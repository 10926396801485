import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCampaigns, setCampaignsStatuses, setCampaignDetailsLinkedinAccount } from "redux/features/linkedboostStore.js";
import { useAPI, Card, Table, useNavigate, ViewContext } from "components/lib";
import { badges } from "../helpers/helpers";

const CampaignsTable = () => {
  const dispatch = useDispatch();
  const linkedins = useAPI("/api/list-all-linkedin-accounts");
  const [linkedinAccounts, setLinkedinAccounts] = useState([]);
  const list = useAPI("/api/list-all-campaigns");
  const campaignsState = useSelector((state) => state.linkedboostStore.campaigns);
  const context = useContext(ViewContext);
  const navigate = useNavigate();
  const editCampaign = (res) => navigate(`/campaigns/update/${res.id}`, { state: res.id });
  const navigateToLeads = (res) => navigate(`./leads/${res.id}`, { state: res.id });
  const navigateToDetails = (res) => {
    if (res.linkedinAccount_ids.length === 1) {
      const linkedin = linkedinAccounts.filter((item) => item.id === res.linkedinAccount_ids[0]);
      dispatch(setCampaignDetailsLinkedinAccount(linkedin));
    }
    navigate(`./${res.id}`, { state: res.id });
  };
  const updateCampaignStatus = (data, callback) => {
    context.modal.show({
      title: data.status === "running" ? "PAUSE CAMPAIGN" : "RESUME CAMPAIGN",
      form: {
        campain_id: {
          type: 'hidden',
          value: data.id,
        },
        status: {
          type: 'hidden',
          value:
            (data.status === "running" && "paused") ||
            (data.status === "paused" && "running"),
        },
        settings: {
          type: "hidden",
          value: {
            profile_visits_per_day: null,
            number_of_pages_to_scrape: null,
          },
        },
      },
      buttonText: "Yes",
      text: `Do you want to pause ${data.name}'s campaign?`,
      url: "/api/update-campaign",
      method: "POST",
    },
      () => {
        context.notification.show("Campaign paused succesfully", "success", true);
        callback();
      });
  };

  useEffect(() => {
    if (list?.data?.length) {
      dispatch(setCampaigns(list?.data));
      let data = [];
      for (let i = 0; i < list.data.length; i++) {
        data.push(list.data[i].status);
      }
      dispatch(setCampaignsStatuses(data));
    }
  }, [list.data]);

  useEffect(() => {
    if (linkedins?.data?.length) {
      setLinkedinAccounts(linkedins.data);
    }
  }, [linkedins.data]);

  return (
    <Card className="xl:mx-10 shadow-md">
      <Table
        search
        className="restrict-width"
        data={campaignsState}
        loading={list.loading}
        show={["name", "description", "status", "date_created"]}
        actions={{
          edit: editCampaign,
          custom: [
            { icon: "eye", action: navigateToDetails, condition: { col: "status", value: "running" } },
            { icon: "users", action: navigateToLeads, condition: { col: "status", value: "running" } },
            { icon: "pause", action: updateCampaignStatus, condition: { col: "status", value: "running" } },
            { icon: "play", action: updateCampaignStatus, condition: { col: "status", value: "paused" } },
          ],
        }}
        badge={badges}
      />
    </Card>
  );
};

export default CampaignsTable;
