import { useContext } from "react";
import { ViewContext, Form, Icon } from "components/lib";
import { CSSTransition } from "react-transition-group";
import "./modalShowParent.scss";
import noImage from "./null_pic.webp";

export function ModalShowParent(props) {
  console.log(props);
  const context = useContext(ViewContext);
  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) =>
          e.target === e.currentTarget && context.modal.hide(true)
        }
      >
        <div className="modal-content bg-white p-4 pb-6">
          <div>
            <div className="flex justify-end">
              <div
                onClick={(e) => context.modal.hide(true)}
                className="border border-slate-400 shadow-md p-2 right-2 top-2 rounded-full 
                absolute cursor-pointer hover:bg-slate-800 hover:text-white bg-white"
              >
                <Icon image="x" size={20} />
              </div>
            </div>
            <img
              className="h-[300px] w-full object-cover"
              src={props.profile_picture ? props.profile_info.profile_picture : noImage}
            />

            <h1 className="text-left ml-8 mt-6 text-2xl mt-2 text-slate-700 font-bold italic">
              {props.first_name && props.last_name
                ? props.first_name + " " + props.last_name
                : "No name"}
            </h1>
            <h1 className="text-left text-lg text-slate-500 mt-2 px-8">
              {props.info
                ? props.info
                : "Software Developer"}
            </h1>
            <div className="flex items-center justify-start mt-4 ml-8">
              <Icon image="briefcase" size={20} className="mr-2" />
              <h1 className="text-left text-lg text-slate-700">
                {props.company_name
                  ? props.company_name
                  : "Good Software Dev"}
              </h1>
            </div>

            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="linkedin" size={20} className="mr-2 mb-1" />

              <a href="https://linkedin.com">
                <h1 className="text-left text-lg text-blue-600">
                  {props.profile_id
                    ? `linkedin.com/${props.profile_id}`
                    : "linkedin.com/unknown"}
                </h1>
              </a>
            </div>

            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="activity" size={20} className="mr-2" />
              {props.status === "active" && <h1 className="text-left text-lg text-green-500 font-bold">{props.status.toUpperCase()}</h1>}
              {props.status === "invalid_credentials" && <h1 className="text-left text-lg text-red-500 font-bold">{props.status.toUpperCase()}</h1>}
              {props.status === "new" && <h1 className="text-left text-lg text-blue-500 font-bold">{props.status.toUpperCase()}</h1>}
            </div>

            {props.text && (
              <div>
                <p className="mt-4 font-bold text-red-600 text-sm">*Note</p>
                <p className="text-left text-sm">{props.text}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
