import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));

const updateLinkedinAccount = (res, setLoading, data, context) => {
  setLoading(true);
  const updateURL = "/api/update-linkedin-email-and-password";
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
  };
  const formData = {
    linkedinAccount_id: data.id,
    email: data.email,
    password: res.password.value,
  };
  axios.patch(updateURL, formData, config)
    .then(() => {
      const loginURL = '/api/login-linkedin-account';
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      };
      const form = { linkedinAccountId: data.id };
      axios.post(loginURL, form, config).then((res) => {
        setLoading(false);
        console.log("LOGIN SUCESS");
        context.modal.show({
          title: res.data.data.status,
          text: res.data.data.message,
        });
      }).catch((err) => {
        console.log("LOGIN ERROR");
        context.notification.show(err.response.data.message, 'error', true);
        setLoading(false);
      });
    }).catch((err) => {
      setLoading(false);
      console.log(err);
      context.notification.show(err.response.data.message, 'error', true);
    });
};

export { updateLinkedinAccount };
