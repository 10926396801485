import { Icon } from "components/lib";

const LeadsLoader = ({ title }) => {
  return (
    <div className="h-[37.5rem] flex justify-center items-center">
      <div className="flex flex-col items-center">
        <h1>{title}</h1>
        <div className="animate-loading">
          <Icon image='loader' size={80} />
        </div>
      </div>
    </div>
  );
};

export default LeadsLoader;
