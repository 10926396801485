import Style from "./selectCampaign.tailwind";

const SelectCampaign = ({ replied, setLeadByCampaign, selectOptions }) => (
  <form className={Style.form}>
    <select
      className={Style.select}
      onChange={(e) => setLeadByCampaign(replied.filter((item) => item.campaign_id === e.target.value))}>
      <option value="" defaultValue={true}>Filter by campaign</option>
      {selectOptions.length > 0 && selectOptions.map((item, index) => {
        return <option key={index + 1} value={item.value}>{item.name}</option>;
      })}
    </select>
  </form>
);

export { SelectCampaign };
