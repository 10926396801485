const Styles = {
  container: "h-3/4 flex flex-col",
  title: "text-slate-700 text-lg tracking-wide antialiased pb-2",
  messagesContainer: "w-full border-x border-b border-slate-300 rounded-b-lg h-full shadow-sm overflow-y-auto bg-slate-50",
  message: "flex flex-col items-start justify-start p-3 border-b border-slate-200 bg-white",
  innerMessage: "flex justify-start w-full items-center",
  imageContainer: "flex w-[60px] overflow-hidden h-[3.5em]",
  image: "border border-slate-200 rounded-full antialiased object-cover",
  textWrapper: "pl-3 w-full flex flex-col",
  header: "flex justify-between w-full",
  user: "font-semibold text-sm text-black m-0 p-0",
  card: "w-[90%] text-base text-slate-700",

  // no messages
  nomsgContainer: "h-3/4 flex flex-col",
  nomsgTitleContainer: "border-b border-slate-300 shadow-xl",
  nomsgTitle: "text-slate-700 tracking-wide antialiased pb-2",
  nomsgWrapper: `w-full border-x border-b border-slate-300 rounded-b-lg h-full 
  shadow-sm overflow-y-auto bg-slate-50 flex items-center justify-center`,
  nomsg: "text-base text-slate-500 text-center",
};

export default Styles;
