import { Icon } from "components/lib";
import Styles from "./updateGoalsButton.tailwind";

const UpdateGoalsButton = ({ overallModal, handleModal, goals }) => (
  <div className={Styles.container}>
    {overallModal ? (
      <button className={Styles.button} onClick={handleModal}>
        <Icon image={goals.length > 0 ? 'refresh-ccw' : 'plus'} size={20} />
        {goals.length > 0 ? '' : 'Create Goals'}
      </button>
    ) : (
      <button className={Styles.button} onClick={handleModal}>
        <Icon image='refresh-ccw' size={20} />
      </button>
    )}
  </div>
);

export default UpdateGoalsButton;
