import { Fragment } from "react";
import { TitleRow, FormCampaignDetails, useNavigate } from "components/lib";
import { formUpdater } from "../helpers/functionHelpers";
import { useDispatch } from "react-redux";
import { buttonsConnectionRequest } from "../helpers/buttonsConnectionRequest";

const CampaignDetails = ({
  formData1,
  campaignDetails,
  nextTab,
  index,
  checkFollowUps,
  viewContext,
  setIndex,
  followUpMessages,
  setSalesNavAccount,
  setConnectionRequest,
  setLinkedins,
  setCampaignDetails,
  connectionRequest,
  linkedins,
  daysOfTheWeekSelected,
  setDaysOfTheWeekSelected,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buttons = ["First Name", "Last Name", "Company", "Location"];
  localStorage.setItem("file", "[]");

  return (
    <Fragment>
      <TitleRow className="flex py-4">
        <h1 className="text-2xl">Campaign Details</h1>
        <p className="text-slate-500">Fill all information below</p>
      </TitleRow>

      <FormCampaignDetails
        data={formData1(campaignDetails)[0]}
        buttonText="Next"
        cancel={() => {
          navigate("/campaigns");
          setDaysOfTheWeekSelected(["1", "2", "3", "4", "5"]);
          campaignDetails.name = "";
          campaignDetails.description = "";
          campaignDetails.working_days = "";
          campaignDetails.search_url = "";
          campaignDetails.linkedinAccount_ids = "";
          campaignDetails.connection_request_message = "";
          campaignDetails.follow_up_messages = "";
          campaignDetails.settings = "";
        }}
        callback={(form) => {
          if (form.upload_csv.length === 0 && form.toggle === true) {
            viewContext.notification.show("Please upload a csv file or add an url to continue", "error", true);
          } else if (form.search_url.length === 0 && form.toggle === undefined) {
            viewContext.notification.show("Please be sure to add a search_url", "error", true);
          } else if (!form.upload_csv.name?.includes(".csv") && form.toggle) {
            viewContext.notification.show("Please make sure you are uploading a csv file", "error", true);
          } else {
            console.log("nextTab called");
            nextTab(form, index, checkFollowUps, viewContext, setIndex, followUpMessages);
          }
        }}
        className="md:flex md:flex-wrap justify-between"
        updateOnChange
        onChange={(form) => {
          if (form.input === "toggle") localStorage.setItem("updateFile", form.value);
          formUpdater(
            form,
            dispatch,
            setSalesNavAccount,
            setConnectionRequest,
            setLinkedins,
            campaignDetails,
            setCampaignDetails,
          );
        }}
        instructions={buttonsConnectionRequest(buttons, connectionRequest, setConnectionRequest)}
        labels={linkedins}
        connectionRequest={connectionRequest}
        setConnectionRequest={setConnectionRequest}
        daysOfTheWeekSelected={daysOfTheWeekSelected}
        setDaysOfTheWeekSelected={setDaysOfTheWeekSelected}
      />
    </Fragment>
  );
};

export default CampaignDetails;
