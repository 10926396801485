import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMessage } from "redux/features/linkedboostStore";
import ChatWindow from "../chatWindow/chatWindow";
import ChatInput from "../chatInput/chatInput";
import Details from "../details/details";
import { users } from "../../helpers/mock";
import { Configuration, OpenAIApi } from "openai";
import Style from "./chatViewer.tailwind";

const ChatViewer = () => {
  const dispatch = useDispatch();
  const fetchMessage = useSelector((state) => state.linkedboostStore.selectedMessage);
  const repliedLeads = useSelector((state) => state.linkedboostStore.repliedLeads);
  const [conversation, setConversation] = useState(users.messages);
  const [autoRes, setAutoRes] = useState(undefined);
  const configuration = new Configuration({ apiKey: process.env.OPEN_AI_KEY });
  const openai = new OpenAIApi(configuration);

  useEffect(() => {
    if (fetchMessage === undefined || fetchMessage.length === 0) dispatch(setSelectedMessage(repliedLeads[0]));
  }, [dispatch, repliedLeads]);

  useEffect(() => {
    if (fetchMessage !== undefined && fetchMessage.messages?.length > 0) {
      setConversation(JSON.stringify(fetchMessage.messages));
    }
  }, [fetchMessage]);

  // const createAutomatedResponse = async (e, conversation) => {
  //   e.preventDefault();
  //   const res = await openai.createCompletion({
  //     model: "text-davinci-003",
  //     prompt: `Please provide an appropiate response based on the conversation context provided. The conversation provided is an
  //     array of objects where every object is a message, if the message object has a lead property set to true then is a reply from the
  //     customer.
  //     conversation: ${conversation}
  //     response: 
  //     `,
  //     max_tokens: 60,
  //   });
  //   setAutoRes(res.data.choices[0].text.trim());
  // };

  return (
    <div className={Style.outerWrapper}>
      <div className={Style.innerWrapper}>
        <ChatWindow fetchMessage={fetchMessage} />
        <ChatInput
          autoRes={autoRes}
          createAutomatedResponse={() => console.log("openai API request here")}
          conversation={conversation}
        />
      </div>
      <Details fetchMessage={fetchMessage} title={"Details"} />
    </div>
  );
};

export default ChatViewer;
