/***
*
*   LOADER
*   Infinite spinning animation for loading states
*
**********/

import logo from './images/safari-pinned-tab.svg';
import { ClassHelper } from 'components/lib';
import Style from './loader.tailwind.js';

export function Loader(props) {

  const loaderStyle = ClassHelper(Style, props);

  return (
    <div className={loaderStyle}>
      <img src={logo} className={Style.orbit} alt='Orbit Spinner' />
    </div>
  );
}
