import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/helpers/help';
import { OnboardingView } from 'views/onboarding/onboarding';
import { OnboardingView2fa } from 'views/onboarding2fa/onboarding2fa';
import { RevalidateAccount } from 'views/onboarding2fa/revalidate';
import { Linkedins } from 'views/linkedins/linkedins';
import { Campaigns } from 'views/campaigns/campaigns';
import { CreateCampaign } from 'views/campaigns/createCampaign';
import { Leads } from 'views/leads/leads';
import { CampaignDetails } from 'views/campaigns/campaignDetails';
import { UpdateCampaign } from 'views/campaigns/updateCampaign';
import Chat from 'views/chat/chat';

import Example from 'views/componentTest/test';

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome',
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help',
  },
  {
    path: '/linkedins',
    view: Linkedins,
    layout: 'app',
    permission: 'user',
    title: 'Linkedins',
  },
  {
    path: '/addlinkedin',
    view: OnboardingView2fa,
    layout: 'app',
    permission: 'user',
    title: 'Add Linkedin Account',
  },
  {
    path: '/revalidate',
    view: RevalidateAccount,
    layout: 'app',
    permission: 'user',
    title: 'Add Linkedin Account',
  },
  {
    path: '/campaigns',
    view: Campaigns,
    layout: 'app',
    permission: 'user',
    title: 'Campaigns',
  },
  {
    path: '/inbox',
    view: Chat,
    layout: 'app',
    permission: 'user',
    title: 'Inbox',
  },
  {
    path: '/campaigns/createCampaign',
    view: CreateCampaign,
    layout: 'app',
    permission: 'user',
    title: 'Campaign Setup',
  },
  {
    path: '/campaigns/:id',
    view: CampaignDetails,
    layout: 'app',
    permission: 'user',
    title: 'Campaign Information',
  },
  {
    path: '/campaigns/update/:id',
    view: UpdateCampaign,
    layout: 'app',
    permission: 'user',
    title: 'Update Campaign',
  },
  {
    path: '/campaigns/leads/:id',
    view: Leads,
    layout: 'app',
    permission: 'user',
    title: 'Created Leads',
  },
  {
    path: '/campaigns/leads/:id/:id/chat',
    view: Chat,
    layout: 'app',
    permission: 'user',
    title: 'Inbox',
  },
  {
    path: '/test',
    view: Example,
    layout: 'app',
    permission: 'user',
    title: 'Tailwind Table Component Test',
  },
];

export default Routes;
