import { useEffect, useState } from "react";
import { Chart, Form, Card, TabViewCustom } from "components/lib";
import { useSelector, useDispatch } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CreateGraphData from "../../helpers/createGraphData";
import { setSelectedCampaignData } from "redux/features/linkedboostStore";
import monthlyGraphData, { has31Days, quarterlyGraphData, createOverallGraphData, createYearlyGraphData, createDateRangeGraphData } from "../../helpers/graphDataHelper";
import Styles from "./sentConnections.tailwind";

const SentConnectionsGraph = () => {
  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state.linkedboostStore.campaigns);
  const campaingsInfo = useSelector((state) => state.linkedboostStore);
  const selectedCampaignData = useSelector((state) => state.linkedboostStore.selectedCampaignData);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [aux, setAux] = useState([]);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const handleValueChange = (newValue) => {
    localStorage.setItem('dateRange', JSON.stringify(newValue));
  };

  const [startDate, setStartDate] = useState(null);
  const selectOptions = campaigns.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  useEffect(() => {
    if (Object.keys(campaingsInfo)) {
      setAux(campaingsInfo.stats.campaignsInfo);
    }
  }, [campaingsInfo.stats.campaignsInfo]);
  let template = {
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Accepted Connections",
          data: [],
          borderColor: "#FFFFFF",
          backgroundColor: [
            "#8D8CC3",
            "#9A99CB",
            "#A6A5D7",
            "#B6B5E4",
            "#C5C4F1",
            "#CECDF8",
          ],
          hoverBorderColor: "transparent",
        },
        {
          label: "Pending Connections",
          data: [],
          borderColor: "#FFFFFF",
          backgroundColor: [
            "#8D8CC3",
            "#9A99CB",
            "#A6A5D7",
            "#B6B5E4",
            "#C5C4F1",
            "#CECDF8",
          ],
          hoverBorderColor: "transparent",
        },
        {
          label: "Requested Connections",
          data: [],
          borderColor: "#FFFFFF",
          backgroundColor: [
            "#8D8CC3",
            "#9A99CB",
            "#A6A5D7",
            "#B6B5E4",
            "#C5C4F1",
            "#CECDF8",
          ],
          hoverBorderColor: "transparent",
        },
      ],
    },
    loading: false,
  };
  const [templateState, setTemplateState] = useState(template);

  useEffect(() => {
    if (!selectedCampaignData.length || campaingsInfo.length > 0) {
      const graphObject = createOverallGraphData(campaingsInfo.stats.campaignsInfo);
      dispatch(setSelectedCampaignData(graphObject));
      template.data.datasets[0].data = graphObject.acceptedConnectionsData;
      template.data.datasets[1].data = graphObject.pendingConnectionsData;
      template.data.datasets[2].data = graphObject.requestedConnectionsData;
      setTemplateState(template);
    }
  }, [campaingsInfo.stats.campaignsInfo]);

  return (
    <>
      <div className={Styles.selectContainer}>
        <Form
          className="w-full"
          data={{
            campaign: {
              label: "Select a Campaign",
              type: "select",
              options: selectOptions.length ? selectOptions : [{ label: 'No campaigns yet, add one...', value: 'No campaigns yet, add one...' }],
            },
          }}
          updateOnChange
          onChange={(e) => {
            setStartDate(null);
            if (e.value !== 'unselected' && e.value !== 'No campaigns yet, add one...') {
              setSelectedCampaign(e.value);
              let selectedCampaign = aux.filter((item) => item.campaignId === e.value);
              const graphObject = CreateGraphData(selectedCampaign);
              dispatch(setSelectedCampaignData(graphObject));
              template.data.datasets[0].data = graphObject.acceptedConnectionsData;
              template.data.datasets[1].data = graphObject.pendingConnectionsData;
              template.data.datasets[2].data = graphObject.requestedConnectionsData;
              setTemplateState(template);
            } else if (e.value === 'unselected') {
              setSelectedCampaign(null);
              const graphObject = createOverallGraphData(campaingsInfo.stats.campaignsInfo);
              dispatch(setSelectedCampaignData(graphObject));
              template.data.datasets[0].data = graphObject.acceptedConnectionsData;
              template.data.datasets[1].data = graphObject.pendingConnectionsData;
              template.data.datasets[2].data = graphObject.requestedConnectionsData;
              setTemplateState(template);
            }
          }}
        />
        <div className={Styles.tabViews}>
          <TabViewCustom
            name="Activity"
            labels={["Montly", "Quarterly", "Yearly", "Date Range"]}
          >
            <Card>
              <DatePicker
                selected={startDate}
                placeholderText="Select a month"
                onChange={(date) => {
                  setStartDate(date);
                  if (selectedCampaign === null) {
                    const graphObject = monthlyGraphData(campaingsInfo.stats.campaignsInfo, date.getMonth());
                    dispatch(setSelectedCampaignData(graphObject));
                    template.data.datasets[0].data = graphObject.acceptedConnectionsData;
                    template.data.datasets[1].data = graphObject.pendingConnectionsData;
                    template.data.datasets[2].data = graphObject.requestedConnectionsData;
                    if (has31Days(date.getMonth())) {
                      template.data.labels = Array(31).fill(0).map((_, i) => i + 1);
                    } else if (date.getMonth() === 1) {
                      template.data.labels = Array(28).fill(0).map((_, i) => i + 1);
                    }
                    else if (!has31Days(date.getMonth())) {
                      template.data.labels = Array(30).fill(0).map((_, i) => i + 1);
                    }
                    setTemplateState(template);
                  } else {
                    let selectedCampaignArray = aux.filter((item) => item.campaignId === selectedCampaign);
                    const graphObject = monthlyGraphData(selectedCampaignArray, date.getMonth());
                    dispatch(setSelectedCampaignData(graphObject));
                    template.data.datasets[0].data = graphObject.acceptedConnectionsData;
                    template.data.datasets[1].data = graphObject.pendingConnectionsData;
                    template.data.datasets[2].data = graphObject.requestedConnectionsData;
                    if (has31Days(date.getMonth())) {
                      template.data.labels = Array(31).fill(0).map((_, i) => i + 1);
                    } else if (date.getMonth() === 1) {
                      template.data.labels = Array(28).fill(0).map((_, i) => i + 1);
                    }
                    else if (!has31Days(date.getMonth())) {
                      template.data.labels = Array(30).fill(0).map((_, i) => i + 1);
                    }
                    setTemplateState(template);
                  }
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="border border-slate-300 p-2 rounded-md cursor-pointer"
              />
            </Card>
            <Card>
              <DatePicker
                selected={startDate}
                placeholderText="Select a quarter"
                onChange={(date) => {
                  setStartDate(date);
                  if (selectedCampaign === null) {
                    const graphObject = quarterlyGraphData(campaingsInfo.stats.campaignsInfo, date.getMonth());
                    dispatch(setSelectedCampaignData(graphObject));
                    template.data.datasets[0].data = graphObject.acceptedConnectionsQuarter;
                    template.data.datasets[1].data = graphObject.pendingConnectionsQuarter;
                    template.data.datasets[2].data = graphObject.requestedConnectionsQuarter;
                    template.data.labels = graphObject.labels;
                    setTemplateState(template);
                  } else {
                    let selectedCampaignArray = aux.filter((item) => item.campaignId === selectedCampaign);
                    const graphObject = quarterlyGraphData(selectedCampaignArray, date.getMonth());
                    dispatch(setSelectedCampaignData(graphObject));
                    template.data.datasets[0].data = graphObject.acceptedConnectionsQuarter;
                    template.data.datasets[1].data = graphObject.pendingConnectionsQuarter;
                    template.data.datasets[2].data = graphObject.requestedConnectionsQuarter;
                    template.data.labels = graphObject.labels;
                    setTemplateState(template);
                  }
                }}
                dateFormat="yyyy, QQQ"
                showQuarterYearPicker
                className="border border-slate-300 p-2 rounded-md cursor-pointer"
              />
            </Card>
            <Card>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  if (selectedCampaign === null) {
                    const graphObject = createYearlyGraphData(campaingsInfo.stats.campaignsInfo, date.getFullYear());
                    dispatch(setSelectedCampaignData(graphObject));
                    template.data.datasets[0].data = graphObject.acceptedConnectionsData;
                    template.data.datasets[1].data = graphObject.pendingConnectionsData;
                    template.data.datasets[2].data = graphObject.requestedConnectionsData;
                    setTemplateState(template);
                  } else {
                    let selectedCampaignArray = aux.filter((item) => item.campaignId === selectedCampaign);
                    const graphObject = createYearlyGraphData(selectedCampaignArray, date.getFullYear());
                    dispatch(setSelectedCampaignData(graphObject));
                    template.data.datasets[0].data = graphObject.acceptedConnectionsData;
                    template.data.datasets[1].data = graphObject.pendingConnectionsData;
                    template.data.datasets[2].data = graphObject.requestedConnectionsData;
                    setTemplateState(template);
                  }
                }}
                showYearPicker
                dateFormat="yyyy"
                className="border border-slate-300 p-2 rounded-md cursor-pointer"
              />
            </Card>
            <Card>
              <Datepicker
                value={value}
                onChange={(date) => {
                  handleValueChange(date);
                  setValue(date);
                  if (selectedCampaign === null) {
                    const graphObject = createDateRangeGraphData(campaingsInfo.stats.campaignsInfo, date.startDate, date.endDate);
                    dispatch(setSelectedCampaignData(graphObject));
                    template.data.datasets[0].data = graphObject.acceptedConnectionsData;
                    template.data.datasets[1].data = graphObject.pendingConnectionsData;
                    template.data.datasets[2].data = graphObject.requestedConnectionsData;
                    template.data.labels = graphObject.labels;
                    setTemplateState(template);
                  } else {
                    let selectedCampaignArray = aux.filter((item) => item.campaignId === selectedCampaign);
                    const graphObject = createDateRangeGraphData(selectedCampaignArray, date.startDate, date.endDate);
                    dispatch(setSelectedCampaignData(graphObject));
                    template.data.datasets[0].data = graphObject.acceptedConnectionsData;
                    template.data.datasets[1].data = graphObject.pendingConnectionsData;
                    template.data.datasets[2].data = graphObject.requestedConnectionsData;
                    template.data.labels = graphObject.labels;
                    setTemplateState(template);
                  }
                }}
                primaryColor={"teal"}
                showShortcuts={true}
              />
            </Card>
          </TabViewCustom>
        </div>
      </div>
      {selectedCampaign === null ? (
        <Card title="Overall Connections">
          <Chart
            title="Accepted Connections"
            type="line"
            legend
            data={templateState.data}
            xAxis
            yAxis
            abbr
            height={200}
          />
        </Card>
      ) : (
        <Card title="Connections by Campaign">
          <Chart
            title="Accepted Connections"
            type="line"
            legend
            data={templateState.data}
            xAxis
            yAxis
            abbr
            height={200}
          />
        </Card>
      )}
    </>
  );
};

export default SentConnectionsGraph;
