import { Icon, Animate } from "components/lib";
import Styles from "./messageModal.tailwind";

const MessageModal = ({
  setModal,
  user,
  updateFavorite,
}) => {

  console.log("from modal", user);
  return (
    <div>
      <div className={Styles.blocker} onClick={() => setModal(false)} />
      <Animate type="slidedown" timeout={100}>
        <div className={Styles.container}>
          <ul className={Styles.list}>
            <li
              className={Styles.listItem}
              onClick={() => {
                updateFavorite(user.id);
                setModal(false);
              }}
            >
              {user.favorite ? (
                <>
                  <Icon image="star" size={15} className="mr-1 text-yellow-400" />
                  <span>Add to favorites</span>
                </>
              ) : (
                <>
                  <Icon image="star" size={15} className="mr-1" />
                  <span>Add to favorites</span>
                </>
              )}

            </li>
            <li
              className={Styles.listItem}
              onClick={() => console.log("Delete message")}
            >
              <Icon image="trash" size={15} className="mr-1" />
              Delete
            </li>
            <li
              className={Styles.listItem}
              onClick={() => {
                console.log("Enable actions in => messageModal.js");
              }}
            >
              <Icon image="archive" size={15} className="mr-1" />
              Archive
            </li>
          </ul>
        </div>
      </Animate>
    </div>
  );
};

export default MessageModal;
