import { createSlice } from '@reduxjs/toolkit';

export const linkedboostStoreSlice = createSlice({
  name: 'linkedBoostStore',
  initialState: {
    stats: {},
    linkedinAccounts: [],
    salesNavAccounts: [],
    campaigns: [],
    campaignsStatuses: [],
    selectedCampaignData: [],
    selectedCampaignLeads: [],
    selectedLead: {},
    selectedLeads: [],
    repliedLeads: [],
    messagesByCampaign: [],
    salesNavAccount: false,
    messages: [],
    selectedMessage: [],
    csvFile: [],
    campaignDetailsLinkedinAccount: [],
  },
  reducers: {
    setAccounts: (state, action) => {
      state.linkedinAccounts = action.payload;
    },
    deleteAccount: (state, action) => {
      const auxState = state.linkedinAccounts.filter(
        (item) => item.id !== action.payload,
      );
      state.linkedinAccounts = auxState;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    deleteCampaign: (state, action) => {
      const auxState = state.campaigns.filter(
        (item) => item.id !== action.payload,
      );
      state.campaigns = auxState;
    },
    setSelectedCampaignLeads: (state, action) => {
      state.selectedCampaignLeads = action.payload;
    },
    setSelectedLead: (state, action) => {
      state.selectedLead = action.payload;
    },
    setSelectedLeads: (state, action) => {
      state.selectedLeads = action.payload;
    },
    setRepliedLeads: (state, action) => {
      state.repliedLeads = action.payload;
    },
    setSalesNavAccount: (state, action) => {
      state.salesNavAccount = action.payload;
    },
    setSalesNavAccounts: (state, action) => {
      state.salesNavAccounts = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setSelectedCampaignData: (state, action) => {
      state.selectedCampaignData = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setSelectedMessage: (state, action) => {
      state.selectedMessage = action.payload;
    },
    setCampaignsStatuses: (state, action) => {
      state.campaignsStatuses = action.payload;
    },
    setCsvFile: (state, action) => {
      state.csvFile = action.payload;
    },
    setMessagesByCampaign: (state, action) => {
      state.messagesByCampaign = action.payload;
    },
    setCampaignDetailsLinkedinAccount: (state, action) => {
      state.campaignDetailsLinkedinAccount = action.payload;
    },
  },
});

export const {
  setAccounts,
  setCampaigns,
  deleteAccount,
  deleteCampaign,
  setSelectedCampaignLeads,
  setSelectedLead,
  setSelectedLeads,
  setRepliedLeads,
  setSalesNavAccount,
  setSalesNavAccounts,
  setStats,
  setSelectedCampaignData,
  setMessages,
  setSelectedMessage,
  setCampaignsStatuses,
  setCsvFile,
  setMessagesByCampaign,
  setCampaignDetailsLinkedinAccount,
} = linkedboostStoreSlice.actions;
export default linkedboostStoreSlice.reducer;
