import { Label, Error, ClassHelper, Icon, Loader } from "components/lib";
import Style from "./selectWindow.tailwind";
import WindowedSelect from "react-windowed-select";
import { useSelector } from "react-redux";

export function SelectWindow(props) {
  const error = props.errorMessage || "Please select an option";
  const salesNavAccounts = useSelector(
    (state) => state.linkedboostStore.salesNavAccounts,
  );
  let salesOptions = salesNavAccounts.map((account) => ({
    value: account.value,
    label: account.label,
  }));

  return (
    <>
      <div className={Style.input + " " + props.containerClass}>
        <Label text={props.label} required={props.required} for={props.name} />
        <WindowedSelect
          options={
            salesNavAccounts?.length > 0
              ? salesOptions
              : [{ value: "unselected", label: "No sales navigator accounts registered..." }]
          }
          onChange={(e) => {
            props.onChange(props.name, e, true);
          }}
          id={props.name}
          isMulti={salesNavAccounts?.length > 0 ? true : false}
          isSearchable
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary: "none",
              neutral10: "#BFDBFE",
              neutral80: "#18181B",
              neutral15: "#18181B",
              dangerLight: "#BFDBFE",
              danger: "#52525B",
            },
          })}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              minHeight: "50px",
            }),
          }}
        />
        {props.valid === false && (
          <Error message={error} className={Style.message} />
        )}
      </div>
    </>
  );
}
