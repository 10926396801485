import { Icon } from "components/lib";
import { handleSearchMain } from '../../helpers/chatHelper';

const Search = ({ replied, setFiltered }) => (
  <div className="relative h-[7%] border border-slate-200 rounded-md">
    <>
      <Icon image="search" size={20} color='dark' className="absolute left-1 top-3" />
      <input
        placeholder="Search"
        className="p-2 w-full pl-7"
        onChange={(e) => handleSearchMain(e, replied, setFiltered)}
      />
    </>
  </div>
);

export default Search;
