import React, { useContext, useState } from 'react';
import { AuthContext, Onboarding } from 'components/lib';
import AddAccount from './components/addAccount/AddAccount';
import VerifyAccount from './components/verifyAccount/VerifyAccount';

export function OnboardingView2fa() {
  const context = useContext(AuthContext);
  const [submit, setSubmit] = useState(0);
  const [submitVerification, setSubmitVerification] = useState(0);
  const callback2 = () => setSubmitVerification(1);
  let views = [
    {
      name: 'Add account',
      description: `Lets add an account, ${context.user.name}!`,
      component: <AddAccount setSubmit={setSubmit} buttonTextProp={submit} />,
    },
  ];

  if (context.permission.admin) {
    views.push({
      name: 'Verify your account',
      description: 'Invite your team to Linkedboost!.',
      component: (
        <VerifyAccount
          submitProp={callback2}
          buttonTextProp={submitVerification}
        />
      ),
    });
  }
  return <Onboarding save onFinish="/linkedins" views={views} />;
}
