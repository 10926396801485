import { useState } from "react";
import { Label, ClassHelper } from "components/lib";
import Style from "./switch.tailwind.js";
import { useSelector, useDispatch } from "react-redux";
import { setSalesNavAccount } from "redux/features/linkedboostStore.js";

export function SwitchSalesNav(props) {
  // state
  const [on, setOn] = useState(props.default);
  const dispatch = useDispatch();
  const salesNavAccountState = useSelector(
    (state) => state.linkedboostStore.salesNavAccount,
  );
  function toggle() {
    setOn(!on);
    dispatch(setSalesNavAccount(!salesNavAccountState));
    props.onChange(props.name, !on, true);
  }

  const trackStyle = ClassHelper(Style, {
    track: true,
    trackOn: on,
    trackOff: !on,
  });

  const handleStyle = ClassHelper(Style, {
    handle: true,
    handleOn: on,
    handleOff: !on,
  });

  return (
    <div className={Style.switch}>
      <Label
        text={props.label}
        required={props.required}
        className={Style.label}
      />

      <div className={trackStyle} onClick={toggle}>
        <div className={handleStyle}></div>
      </div>
    </div>
  );
}
