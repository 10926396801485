import { Icon } from "components/lib";
import nullImage from "../images/null_pic.webp";

const Details = ({ data, title }) => (
  <>
    {data !== undefined && (
      <div className="flex items-center flex-col lg:flex-row">
        <div className="flex flex-col items-center px-4 shadow-sm w-full lg:w-1/2 mb-4 md: md:mb-0">
          <h1 className="text-lg text-slate-700 mb-2 tracking-wider text-start w-full">{title}</h1>
          <div className="h-[14em] flex items-center overflow-hidden rounded-lg w-full relative object-fill shadow-xl">
            <img src={data.profile_picture ? data.profile_picture : nullImage} className="object-cover" />
          </div>
          <h1 className="text-xl font-bold text-slate-800 mt-2">{data.name}</h1>
          <p className="font text-center">{data.profile_info.sub_title}</p>
        </div>
        <div className="pb-4 pl-4 h-[10em] lg:h-[14em] flex flex-col w-full lg:w-1/2 items-start justify-between">
          <div className="flex items-center justify-start">
            <Icon image="briefcase" size={20} className="mr-2" />
            <h1 className="text-left text-base text-slate-700">
              {data.profile_info.company_name}
            </h1>
          </div>
          <div className="flex items-center justify-start">
            <Icon image="linkedin" size={20} className="mr-2 mb-1" />
            <a href="https://linkedin.com">
              <h1 className="text-left text-base text-blue-600">
                {data.profile_id}
              </h1>
            </a>
          </div>
          <div className="flex items-center justify-start">
            <Icon image="mail" size={20} className="mr-2" />
            <h1 className="text-left text-base text-slate-700">
              {data.email}
            </h1>
          </div>
          <div className="flex items-center justify-start">
            <Icon image="phone" size={20} className="mr-2" />
            <h1 className="text-left text-base text-slate-700">
              phone here
            </h1>
          </div>
          <div className="flex items-center justify-start">
            <Icon image="globe" size={20} className="mr-2" />
            <h1 className="text-left text-base text-slate-700">
              {data.profile_info.location}
            </h1>
          </div>
        </div>
      </div>
    )}

    {data === undefined && (
      <div className="w-[35%] hidden xl:flex xl:flex-col">
        <h1 className="text-lg text-slate-700 font-semibold h-[5%] tracking-wider">
          Details
        </h1>
        <div className="flex flex-col items-center px-4 pb-4 h-78 shadow-sm">
          <div className="h-64 flex items-center overflow-hidden rounded-lg w-[21em] relative object-fill shadow-xl">
            <img src={nullImage} className="object-cover" />
          </div>
        </div>
      </div>
    )}
  </>
);

export default Details;
