export const WORKING_HOURS = [
  "01:00 hr",
  "02:00 hr",
  "03:00 hr",
  "04:00 hr",
  "05:00 hr",
  "06:00 hr",
  "07:00 hr",
  "08:00 hr",
  "09:00 hr",
  "10:00 hr",
  "11:00 hr",
  "12:00 hr",
  "13:00 hr",
  "14:00 hr",
  "15:00 hr",
  "16:00 hr",
  "17:00 hr",
  "18:00 hr",
  "19:00 hr",
  "20:00 hr",
  "21:00 hr",
  "22:00 hr",
  "23:00 hr",
  "00:00 hr",
];
export const DAYS_OF_THE_WEEK = ["1", "2", "3", "4", "5"];
