import { Icon } from "components/lib";

const LbLoader = () => {
  return (
    <div className="z-40 h-full flex justify-center items-center">
      <Icon image="loader" size={50} className="animate-loading" />
    </div>
  );
};

export default LbLoader;
