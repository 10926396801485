import { Signup } from 'views/auth/signup/account';
import { Button, Animate } from 'components/lib';
import Styles from './customeHome.tailwind';
import HowItWorks from '../howItWorks';

export function customHome() {
  return (
    <Animate type="pop">
      <div className={Styles.container}>
        <div className={Styles.heroLeft}>
          <h1 className={Styles.heroTitle}>
            Upscale your business with Linkedboost
            <span className={Styles.heroSpan}>.</span>
          </h1>
          <h2
            className={Styles.subtitle}
            style={{ marginBottom: '0' }}
          >
            Automate your B2B leads generation with Linkedboost!
          </h2>
          <ul className={Styles.list}>
            <li className={Styles.listItem}>
              Discover potential leads based on job titles, industries, and
              locations.
            </li>
            <li className={Styles.listItem}>
              Create customized campaigns tailored to a targeted audience.
            </li>
            <li className={Styles.listItem}>
              Send personalized messages to your leads.
            </li>
            <li className={Styles.listItem}>
              Integrated with LinkedIn, ensuring that businesses can easily
              manage their campaigns from one central location.
            </li>
          </ul>
        </div>
        <Button
          color="grey"
          goto="/signup"
          text="Create an account!"
          className={Styles.button}
        />
        <p className={Styles.mobileSubtitle}>
          Try Linkedboost free-plan, no credit card required.
        </p>
        <div className={Styles.mobileSignUp}>
          <Signup />
        </div>
      </div>
      <HowItWorks />
    </Animate>
  );
}
