import React, { useState, useEffect } from "react";
import { Label, Error, Icon, ClassHelper } from "components/lib";
import Style from "./input.tailwind.js";

export function TextInput(props) {
  const error = props.errorMessage || "Please enter a value";
  const [content, setContent] = useState("");
  function validate(e) {
    let value = e ? e.target.value : "";
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === "") valid = false;

    // input isn't required and value is blank
    if (!props.required && value === "") valid = true;

    if (props.required && value !== "") valid = true;

    // update the parent form
    props.onChange?.(props.name, value, valid);
  }

  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    containerClass: props.containerClass,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  const handleContentChange = (e) => {
    setContent(props.connectionRequest);
  };
  useEffect(() => {
    if (props.connectionRequest) {
      setContent(props.connectionRequest);
    }
  }, [props.connectionRequest]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
    if (e.key === "Backspace" && props.connectionRequest.length === 1) {
      props.setConnectionRequest(" ");
    }
    if (props.connectionRequest.includes("{{" || "}}")) {
      if (
        (e.key === "Backspace" || e.key === "Delete") &&
        props.connectionRequest[props.connectionRequest.length - 1] === "}"
      ) {
        let state = props.connectionRequest.split(/\s+/);
        const result = state.slice(0, -1).join(" ");
        props.setConnectionRequest(result + " ");
      }
    }
    if (
      props.connectionRequest.includes("}}") &&
      e.key === "Backspace" &&
      e.target.selectionStart - 1 === props.connectionRequest.lastIndexOf("}")
    ) {
      let state1 = props.connectionRequest
        .slice(0, e.target.selectionStart)
        .split(" ");
      state1.pop();
      let final = state1.join(" ");
      let state2 = props.connectionRequest.slice(e.target.selectionStart);
      final += state2;
      props.setConnectionRequest(final + " ");
    } else if (
      props.connectionRequest.includes("}}") &&
      e.key === "Backspace" &&
      e.target.selectionStart - 2 === props.connectionRequest.indexOf("}}")
    ) {
      let state1 = props.connectionRequest
        .slice(0, e.target.selectionStart)
        .split(" ");
      state1.pop();
      let final = state1.join(" ");
      let state2 = props.connectionRequest.slice(e.target.selectionStart);
      final += state2;
      props.setConnectionRequest(final + " ");
    }
  };

  return (
    <div className={Style.input + " " + props.containerClass}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      {props.type === "textarea" ? (
        <div>
          <textarea
            id={props.id}
            name={props.name}
            value={
              props.connectionRequest
                ? props.connectionRequest
                : "" || props.value || ""
            }
            className="border border-slate-300 rounded-md h-40 text-md p-3 text-slate-500 w-full absolute bottom-0 left-0 top-7 opacity-30"
            onChange={(e) => {
              if (e.target.value.length === 1 && e.target.value === " ") {
                props.setConnectionRequest("");
              } else if (e.target.value.includes("  ")) {
                let string = e.target.value;
                let result = string.replace(/  +/g, " ");
                props.onChange?.(props.name, result, undefined);
              } else {
                props.onChange?.(props.name, e.target.value, undefined);
              }
            }}
            onBlur={(e) => validate(e)}
            onKeyDown={handleKeyDown}
          ></textarea>
          <div
            className="border border-slate-300 rounded-md h-40 text-md p-3 text-slate-500"
            contentEditable="true"
            onInput={handleContentChange}
            suppressContentEditableWarning={true}
          >
            {content.split(/(\{\{.*?\}\})/).map((word, index) => {
              if (
                word.includes("{{first_name}}") ||
                word.includes("{{last_name}}") ||
                word.includes("{{company}}") ||
                word.includes("{{location}}")
              ) {
                return word === "{{first_name}}" ||
                  word === "{{last_name}}" ||
                  word === "{{company}}" ||
                  word === "{{location}}" ? (
                  <span
                    key={index}
                    className="bg-teal-500 text-slate-200 rounded-sm shadow-lg z-10 mb-4"
                  >
                    <p className="text-teal-300 inline">{word.slice(0, 2)}</p>
                    <p className="inline text-slate-700">{word.slice(2, -2)}</p>
                    <p className="text-teal-300 inline">{word.slice(-2)}</p>
                  </span>
                ) : (
                  word
                );
              } else {
                return word;
              }
            })}

            {content.length === 0 && (
              <p className="inline text-slate-600">
                Enter a friendly connection message here
              </p>
            )}
          </div>
        </div>
      ) : (
        <input
          type="text"
          id={props.id}
          name={props.name}
          value={props.value || ""}
          className={textStyle}
          placeholder={props.placeholder}
          onChange={(e) =>
            props.onChange?.(props.name, e.target.value, undefined)
          }
          onBlur={(e) => validate(e)}
        />
      )}

      {props.valid === false && <Error message={error} />}

      {props.valid === true && (
        <Icon
          image="check"
          color="#8CC57D"
          className={Style.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
