import { Label, Error, ClassHelper, Icon, Loader } from "components/lib";
import Style from "./selectWindow.tailwind";
import WindowedSelect from "react-windowed-select";
import { useSelector } from "react-redux";

export function SelectWindowSingle(props) {
  const options = Array.isArray(props.options) ? props.options : [];
  const error = props.errorMessage || "Please select an option";
  const salesNavAccountState = useSelector(
    (state) => state.linkedboostStore.salesNavAccount,
  );

  return (
    <>
      {!salesNavAccountState && (
        <div className={Style.input + " " + props.containerClass}>
          <Label
            text={props.label}
            required={props.required}
            for={props.name}
          />
          <WindowedSelect
            options={
              options?.length > 0
                ? options.map((field) => ({
                  value: field.value,
                  label: field.label,
                }))
                : [{ value: "unselected", label: "Please add a Linkedin account first..." }]
            }
            onChange={(e) => {
              props.onChange(props.name, e, true);
            }}
            id={props.name}
            isSearchable
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary: "none",
                neutral10: "#BFDBFE",
                neutral80: "#18181B",
                neutral15: "#18181B",
                dangerLight: "#BFDBFE",
                danger: "#52525B",
              },
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minHeight: "50px",
              }),
            }}
          />
          {props.valid === false && (
            <Error message={error} className={Style.message} />
          )}
        </div>
      )}
    </>
  );
}
