import { Form } from 'components/lib';
import Styles from './updateObjectives.tailwind';
import axios from 'axios';

const UpdateObjectivesModal = ({ title, goals }) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <div className={Styles.container}>
      <h1 className={Styles.title}>{title}</h1>
      <Form
        className={Styles.form}
        title='Update Overall Goals'
        data={{
          createdLeads: {
            label: 'Created Leads',
            type: 'number',
            required: true,
            placeholder: 'Created leads goals',
            errorMessage: 'Please enter a valid number',
          },
          requestedConnections: {
            label: 'Requested Connections',
            type: 'number',
            required: true,
            placeholder: 'Requested connections goals',
            errorMessage: 'Please enter a valid number',
          },
          acceptedConnections: {
            label: 'Accepted Connections',
            type: 'number',
            required: true,
            placeholder: 'Accepted connections goals',
            errorMessage: 'Please enter a valid number',
          },
        }}
        buttonText={goals.length > 0 ? 'Update Goals' : 'Create Goals'}
        callback={(data) => {
          const URL = "/api/metrics/goal";
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.token}`,
            },
          };
          if (goals.length === 0) {
            console.log("inside post");
            const form = {
              leads_created: data.createdLeads,
              requested_connections: data.requestedConnections,
              accepted_connections: data.acceptedConnections,
            };
            axios.post(URL, form, config)
              .then(() => window.location.reload())
              .catch((err) => console.log(err.response.data));
          } else {
            const form = {
              id: goals[0].id,
              leads_created: data.createdLeads,
              requested_connections: data.requestedConnections,
              accepted_connections: data.acceptedConnections,
            };
            axios.patch(URL, form, config)
              .then(() => window.location.reload())
              .catch((err) => console.log(err.response.data));
          }
        }}
      />
    </div >
  );
};

export default UpdateObjectivesModal;
