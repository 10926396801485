import React, { useEffect, useState } from 'react';
import { Grid, Card, Form, ProgressBar, Chart, useAPI } from 'components/lib';
import { useSelector } from 'react-redux';
import UpdateObjectivesModal from '../updateObjectives/updateObjectives';
import UpdateGoalsButton from '../updateGoalsButton/updateGoalsButton';
import {
  overallMetricsHelper,
  overallChartHelper,
  filterOverallFormData,
  defaultObjectives,
  filterOverallOnChangeFunction,
} from '../../helpers/helpers';
import Styles from "./filterOverall.tailwind";

const FilterOverall = () => {
  const campaigns = useSelector((state) => state.linkedboostStore.campaigns);
  const stats = useSelector((state) => state.linkedboostStore.stats);
  const overallMetrics = useAPI('/api/list-leads-metrics');
  const fetchGoals = useAPI('/api/metrics/goal');
  const [goals, setGoals] = useState([]);
  const [modal, setModal] = useState(false);
  const [overallModal, setOverallModal] = useState(true);
  const [overallMetricsState, setOverallMetricsState] = useState(null);
  const [overallChartState, setOverallChartState] = useState(null);
  const [progressTitle, setProgressTitle] = useState('Overall Objectives');
  const [chartTitle, setChartTitle] = useState('Overall Leads');
  const [overallObjectives] = useState(defaultObjectives);
  const selectOptions = campaigns.map((item) => { return { label: item.name, value: item.id }; });

  useEffect(() => {
    if (fetchGoals.data) setGoals(fetchGoals.data);
  }, [fetchGoals.data]);

  useEffect(() => {
    if (overallMetrics.data) {
      setOverallMetricsState(overallMetricsHelper(overallMetrics, goals.length > 0 ? goals[0] : overallObjectives));
      if (!overallMetrics.data.totalLeads) {
        setOverallChartState(overallChartState);
      } else {
        setOverallChartState(overallChartHelper(overallMetrics));
      }
    }
  }, [overallMetrics.data, overallObjectives]);

  return (
    <>
      <Form
        className={Styles.container}
        data={filterOverallFormData(selectOptions)}
        updateOnChange
        onChange={(e) => {
          filterOverallOnChangeFunction(
            e,
            stats,
            setOverallModal,
            setProgressTitle,
            setChartTitle,
            setOverallChartState,
            setOverallMetricsState,
            overallMetrics,
            overallMetricsState,
            goals,
          );
        }}
      />
      <Grid cols="2">
        <Card title={progressTitle}>
          <UpdateGoalsButton overallModal={overallModal} handleModal={() => setModal(!modal)} goals={goals} />
          {overallMetricsState?.data?.map((item) => {
            return (
              <ProgressBar
                key={item.label}
                label={item.label}
                progress={item.value}
              />
            );
          })}
        </Card>
        <Card title={chartTitle}>
          <Chart
            type="donut"
            legend={true}
            data={overallChartState?.data}
            color="green"
          />
        </Card>
      </Grid>
      {modal && (
        <div className={Styles.modalBlocker}>
          <div className={Styles.modal} onClick={() => setModal(!modal)} />
          <UpdateObjectivesModal
            modal={modal}
            setModal={setModal}
            goals={goals}
            title={overallModal ? 'Set Overall Objectives' : 'Set Campaign Objectives'}
          />
        </div >
      )}
    </>
  );
};

export default FilterOverall;
