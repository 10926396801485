import React, { useContext, useEffect, useState } from 'react';
import { ViewContext, useLocation, useNavigate } from 'components/lib';
import RevalidateLoader from "../revalidateLoader/RevalidateLoader";
import RevalidateForm from '../revalidateForm/RevalidateForm';
import axios from 'axios';
import Swal from 'sweetalert2';

const VerifyAccountRevalidate = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const modal = useContext(ViewContext);
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (location.state.id) {
      const URL = '/api/login-linkedin-account';
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      };
      const form = { linkedinAccountId: location.state.id };
      axios.post(URL, form, config).then((res) => {
        if (res.data.data.status === '2_factor_pending') {
          setLoading(false);
        } else if (res.data.data.status === 'invalid_credentials') {
          Swal.fire({
            title: 'Invalid credentials 😔',
            text: 'Please check the mail or password provided, we were not able to verify your information👮',
            icon: 'error',
            confirmButtonText: 'Cool',
          });
          navigate('/linkedins');
        } else if (res.data.data.status === 'active') {
          Swal.fire({
            title: 'Account added successfully! 🎉',
            text: 'You can now start using Linkedboost',
            icon: 'success',
            confirmButtonText: 'Cool',
          });
          navigate('/linkedins');
        } else if (res.data.data.status === 'blocked') {
          Swal.fire({
            title: 'Account blocked ❌',
            text: 'This account is blocked, please try with a new one',
            icon: 'error',
            confirmButtonText: 'Cool',
          });
          navigate('/linkedins');
        } else if (res.data.data.status === "new") {
          alert("new status TEST");
        }
      }).catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: err.response.data.message,
          icon: 'error',
          confirmButtonText: 'Cool',
        });
        navigate('/linkedins');
      });
    }
  }, []);

  return (
    <>
      <RevalidateLoader loading={loading} />
      <RevalidateForm loading={loading} />
    </>
  );
};

export default VerifyAccountRevalidate;
