export function buttonsConnectionRequest(buttons, connectionRequest, setConnectionRequest) {
  const res = buttons.map((button) => {
    return (
      <div
        key={button}
        className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded w-1/4 min-w-[55px] text-center mr-1 text-sm flex justify-center items-center"
        onClick={(e) => {
          let state = connectionRequest;
          if (e.target.innerText.includes(' ')) {
            let split = e.target.innerText.split(' ');
            split[0] = split[0].toLowerCase();
            split[1] = split[1].toLowerCase();
            state += `{{${split[0] + '_' + split[1]}}}`;
            setConnectionRequest(state);
          } else {
            let locationState = e.target.innerText.toLowerCase();
            state += `{{${locationState}}}`;
            setConnectionRequest(state);
          }
        }}
      >
        {button}
      </div>
    );
  });
  return res;
}
