import { useState, useEffect } from "react";
import Style from "./inboxViewer.tailwind";
import { useDispatch } from "react-redux";
import { useAPI } from "components/lib";
import MessageChatCards from "../messageChatCards/messageChatCards";
import { setRepliedLeads } from "redux/features/linkedboostStore";
import { SelectCampaign } from "../selectCampaign/selectCampaign";
import ChatPagination from "../chatPagination/ChatPagination";
import Search from "../searchBar/search";
import LbLoader from "../LbLoader/LbLoader";
import axios from 'axios';

const InboxViewer = () => {
  const dispatch = useDispatch();
  const chat = useAPI("/api/chat");
  const [replied, setReplied] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const list = useAPI("/api/list-all-campaigns");
  const [selectOptions, setSelectOptions] = useState([]);
  const [leadsByCampaign, setLeadByCampaign] = useState([]);
  const [messagesPerPage] = useState(5);
  const [usePagination, setUsePagination] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem('user'));

  const paginate = (page) => {
    setUsePagination(usePagination + 1);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (chat.data) {
      setReplied(chat.data);
      dispatch(setRepliedLeads(chat.data));
    }
  }, [chat.data]);

  useEffect(() => {
    if (replied?.length > 0 && list?.data?.length > 0) {
      const campaignIds = [...new Set(replied?.map((item) => item.campaign_id))];
      const campaignOptions = [];
      for (let i = 0; i < campaignIds.length; i++) {
        for (let j = 0; j < list.data.length; j++) {
          if (list.data[j].id === campaignIds[i]) {
            campaignOptions.push({ name: list.data[j].name, value: list.data[j].id });
          }
        }
      }
      setSelectOptions(campaignOptions);
    }
  }, [replied]);

  const updateFavorite = (chatId) => {
    const selectedChat = chat.data.filter((item) => item.id === chatId);
    let favorite;
    if (selectedChat[0].favorite) {
      favorite = false;
    } else {
      favorite = true;
    }
    const URL = "/api/chat";
    const config = {
      headers: {
        'Contenty-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      },
    };
    const data = {
      lead_id: selectedChat[0].lead_id,
      favorite,
    };
    axios.patch(URL, data, config)
      .catch((err) => console.log(err));
  };

  return (
    <div className={Style.inboxWrapper}>
      <h1 className={Style.title}>Messaging</h1>
      <Search replied={replied} setFiltered={setFiltered} />
      <SelectCampaign replied={replied} setLeadByCampaign={setLeadByCampaign} selectOptions={selectOptions} />
      <div className={Style.innerWrapper}>
        {replied.length > 0 && !leadsByCampaign.length && !filtered.length && (
          <>
            <h2 className={Style.subtitle}>{`Inbox (${replied.length})`}</h2>
            <MessageChatCards users={replied} dispatch={dispatch} updateFavorite={updateFavorite} />
          </>
        )}

        {leadsByCampaign.length > 0 && !filtered.length && (
          <>
            <h1 className={Style.subtitle}>{`By Campaign (${leadsByCampaign.length})`}</h1>
            <MessageChatCards users={leadsByCampaign} dispatch={dispatch} />
          </>
        )}

        {filtered.length > 0 && (
          <>
            <h1 className={Style.subtitle}>{`Filter Icon (${filtered.length})`}</h1>
            <MessageChatCards users={filtered} dispatch={dispatch} />
          </>
        )}

        {!replied.length && !chat.loading && (
          <div className={Style.noLeadsWrapper}>
            <h1 className={Style.noLeadsTitle}>
              Your replied leads will appear here 📪
            </h1>
          </div>
        )}

        {chat.loading && <LbLoader />}
      </div>

      {replied.length > 0 && (
        <ChatPagination
          chatsPerPage={messagesPerPage}
          totalChats={replied.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div >
  );
};

export default InboxViewer;
