import React from 'react';
import { Animate, Row, PricePlans } from 'components/lib';

export function Pricing() {

  return (
    <Animate type='pop'>
      {/* <Row color='tint' align='center' title='Start simple, upgrade your plan when required!'> */}
      <div>
        <div className='h-full flex flex-col justify-center items-center md:px-40 md:py-28'>
          <h1 className='text-center text-lg md:text-2xl w-full md:text-start text-slate-800 font-semibold mt-4'>Start simple, upgrade your plan when required</h1>
          <PricePlans />
        </div>
      </div>
    </Animate>
  );
}
