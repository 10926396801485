const monthlyGraphData = (arr, month) => {
  if (arr === undefined) {
    return 0;
  } else {
    let acceptedConnectionsData = Array(31).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let acceptedConnections = arr[i].acceptedConnections.data ?? null;
      if (acceptedConnections != null && acceptedConnections.length > 0 && acceptedConnections[0].month === month) {
        for (let i = 1; i <= 31; i++) {
          if (i === acceptedConnections[0].day) {
            acceptedConnectionsData[acceptedConnections[0].day - 1] += acceptedConnections[0].amount;
          }
        }
      }
    }

    let pendingConnectionsData = Array(31).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let pendingConnections = arr[i].pendingConnections.data ?? null;
      if (pendingConnections != null && pendingConnections.length > 0 && pendingConnections[0].month === month) {
        for (let i = 1; i <= 31; i++) {
          if (i === pendingConnections[0].day) {
            pendingConnectionsData[pendingConnections[0].day - 1] += pendingConnections[0].amount;
          }
        }
      }
    }

    let requestedConnectionsData = Array(31).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let requestedConnections = arr[i].requestedConnections.data ?? null;
      if (requestedConnections != null && requestedConnections.length > 0 && requestedConnections[0].month === month) {
        for (let i = 1; i <= 31; i++) {
          if (i === requestedConnections[0].day) {
            requestedConnectionsData[requestedConnections[0].day - 1] += requestedConnections[0].amount;
          }
        }
      }
    }
    return {
      acceptedConnectionsData,
      pendingConnectionsData,
      requestedConnectionsData,
    };
  }
};

const quarterlyGraphData = (arr, startMonth) => {
  console.log(arr);
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Octr",
    "Nov",
    "Dec",
  ];
  if (arr === undefined) {
    return 0;
  } else {
    const acceptedConnections = arr.length > 0 ? arr[0].acceptedConnections.data : null;
    let labels = [];
    for (let i = 0; i < 3; i++) {
      labels.push(MONTHS[(startMonth + i) % 12]);
    }

    let acceptedConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let acceptedConnections = arr[i].acceptedConnections.data ?? null;
      if (acceptedConnections != null && acceptedConnections.length > 0) {
        for (let i = 0; i < 12; i++) {
          if (i === acceptedConnections[0].month) {
            acceptedConnectionsData[acceptedConnections[0].month] += acceptedConnections[0].amount;
          }
        }
      }
    }

    let pendingConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let pendingConnections = arr[i].pendingConnections.data ?? null;
      if (pendingConnections != null && pendingConnections.length > 0) {
        for (let i = 1; i <= 12; i++) {
          if (i === pendingConnections[0].month) {
            pendingConnectionsData[pendingConnections[0].month] += pendingConnections[0].amount;
          }
        }
      }
    }

    let requestedConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let requestedConnections = arr[i].requestedConnections.data ?? null;
      if (requestedConnections != null && requestedConnections.length > 0) {
        for (let i = 1; i <= 12; i++) {
          if (i === requestedConnections[0].month) {
            requestedConnectionsData[requestedConnections[0].month] += requestedConnections[0].amount;
          }
        }
      }
    }

    const acceptedConnectionsQuarter = acceptedConnectionsData.slice(startMonth, startMonth + 3);
    const pendingConnectionsQuarter = pendingConnectionsData.slice(startMonth, startMonth + 3);
    const requestedConnectionsQuarter = requestedConnectionsData.slice(startMonth, startMonth + 3);

    return {
      acceptedConnectionsQuarter,
      pendingConnectionsQuarter,
      requestedConnectionsQuarter,
      labels,
    };
  }
};

const createOverallGraphData = (arr) => {
  if (arr === undefined) {
    return 0;
  } else {
    let acceptedConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let acceptedConnections = arr[i].acceptedConnections.data ?? null;
      if (acceptedConnections != null && acceptedConnections.length > 0) {
        for (let i = 0; i < 12; i++) {
          if (i === acceptedConnections[0].month) {
            acceptedConnectionsData[acceptedConnections[0].month] += acceptedConnections[0].amount;
          }
        }
      }
    }

    let pendingConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let pendingConnections = arr[i].pendingConnections.data ?? null;
      if (pendingConnections != null && pendingConnections.length > 0) {
        for (let i = 1; i <= 12; i++) {
          if (i === pendingConnections[0].month) {
            pendingConnectionsData[pendingConnections[0].month] += pendingConnections[0].amount;
          }
        }
      }
    }

    let requestedConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let requestedConnections = arr[i].requestedConnections.data ?? null;
      if (requestedConnections != null && requestedConnections.length > 0) {
        for (let i = 1; i <= 12; i++) {
          if (i === requestedConnections[0].month) {
            requestedConnectionsData[requestedConnections[0].month] += requestedConnections[0].amount;
          }
        }
      }
    }
    return {
      acceptedConnectionsData,
      pendingConnectionsData,
      requestedConnectionsData,
    };
  }
};

const createYearlyGraphData = (arr, year) => {
  if (arr === undefined) {
    return 0;
  } else {
    let acceptedConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let acceptedConnections = arr[i].acceptedConnections.data ?? null;
      if (acceptedConnections != null && acceptedConnections.length > 0) {
        for (let i = 0; i < 12; i++) {
          if (i === acceptedConnections[0].month && acceptedConnections[0].year === year) {
            acceptedConnectionsData[acceptedConnections[0].month] += acceptedConnections[0].amount;
          }
        }
      }
    }

    let pendingConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let pendingConnections = arr[i].pendingConnections.data ?? null;
      if (pendingConnections != null && pendingConnections.length > 0) {
        for (let i = 1; i <= 12; i++) {
          if (i === pendingConnections[0].month && pendingConnections[0].year === year) {
            pendingConnectionsData[pendingConnections[0].month] += pendingConnections[0].amount;
          }
        }
      }
    }

    let requestedConnectionsData = Array(12).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let requestedConnections = arr[i].requestedConnections.data ?? null;
      if (requestedConnections != null && requestedConnections.length > 0) {
        for (let i = 1; i <= 12; i++) {
          if (i === requestedConnections[0].month && requestedConnections[0].year === year) {
            requestedConnectionsData[requestedConnections[0].month] += requestedConnections[0].amount;
          }
        }
      }
    }
    return {
      acceptedConnectionsData,
      pendingConnectionsData,
      requestedConnectionsData,
    };
  }
};

const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const createDateRangeGraphData = (arr, startDate, endDate) => {
  console.log("DATES", arr, startDate, endDate);
  if (arr === undefined) {
    return 0;
  } else {
    const startDateFormatted = new Date(startDate);
    startDateFormatted.setDate(startDateFormatted.getDate() + 1);
    const endDateFormatted = new Date(endDate);
    const deltaDays = Math.abs(endDateFormatted - startDateFormatted) / 1000 / 60 / 60 / 24;
    let acceptedConnectionsData = Array(deltaDays !== 0 ? (deltaDays + 2) : deltaDays).fill(0);
    let dayAndMonthTracker = [];
    const startYear = startDateFormatted.getFullYear();
    let startMonth = startDateFormatted.getMonth() + 1;
    let startDay = parseInt(startDate?.slice(-2));

    let endMonth = endDateFormatted.getMonth() + 1;
    let endDay = parseInt(endDate?.slice(-2));
    const numberOfDays = daysInMonth(startMonth, startYear);

    for (let i = startMonth; i < endMonth; i++, startDay = 1) {
      let numberOfDays = daysInMonth(i, startYear);
      for (let j = startDay; j <= numberOfDays; j++) {
        dayAndMonthTracker.push(
          { day: j, month: i },
        );
      }
    }

    for (let i = endMonth; i <= endMonth; i++) {
      for (let j = startDay; j <= endDay; j++) {
        dayAndMonthTracker.push(
          { day: j, month: i },
        );
      }
    }

    for (let i = 0; i < arr.length; i++) {
      let acceptedConnections = arr[i].acceptedConnections.data ?? null;
      if (acceptedConnections.length > 0) {
        for (let i = 0; i < numberOfDays; i++) {
          if (dayAndMonthTracker[i].day === acceptedConnections[0].day &&
            dayAndMonthTracker[i].month - 1 === acceptedConnections[0].month) {
            console.log(dayAndMonthTracker[i]);
            acceptedConnectionsData[acceptedConnections[0].day - 1] += acceptedConnections[0].amount;
          }
          else {
            const prevMonthDays = numberOfDays;
            // const currentMonthDays = daysInMonth(endMonth, startYear);
            const base = prevMonthDays - parseInt(startDate?.slice(-2));
            acceptedConnectionsData[base + acceptedConnections[0].day] += acceptedConnections[0].amount;
          }
        }
      }
    }

    let requestedConnectionsData = Array(deltaDays !== 0 ? (deltaDays + 1) : deltaDays).fill(0);
    for (let i = 0; i < arr.length; i++) {
      let requestedConnections = arr[i].requestedConnections.data ?? null;
      if (requestedConnections.length > 0) {
        for (let i = 0; i < deltaDays; i++) {
          if (i === requestedConnections[0].day &&
            (requestedConnections[0].month >= startDateFormatted.getMonth() &&
              requestedConnections[0].month <= endDateFormatted.getMonth())) {
            requestedConnectionsData[requestedConnections[0].day - 1] += requestedConnections[0].amount;
          }
        }
      }
    }

    let labels = [];
    for (let i = -1; i <= deltaDays; i++) {
      labels.push(new Date(startDateFormatted.getTime() + ((i + 1) * 24 * 60 * 60 * 1000)).toLocaleDateString());
    }

    return {
      acceptedConnectionsData,
      requestedConnectionsData,
      labels,
    };
  }
};

const has31Days = (month) => {
  const date = new Date(2000, month + 1, 1);
  date.setDate(date.getDate() - 1);
  return date.getDate() === 31;
};

export default monthlyGraphData;
export { has31Days, quarterlyGraphData, createOverallGraphData, createYearlyGraphData, createDateRangeGraphData }; 
