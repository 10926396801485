import { BLANK_FOLLOW_UP } from '../../../lib/consts/blankFollowUp';

const nextTab = (form, index, checkFollowUps, viewContext, setIndex) => {
  console.log(form);
  localStorage.setItem('campaignDetails', JSON.stringify(form));
  if (!checkFollowUps(index)) {
    viewContext.notification.show(
      'You are missing fields to complete',
      'error',
      true,
    );
    return;
  }
  const newPosition = parseInt(index) + 1;
  if (newPosition > 1) {
    return;
  }
  localStorage.setItem('AddCampaignTabs', newPosition);
  setIndex(newPosition);
};

const addFollowUp = (followUpMessages, setFollowUpMessages, viewContext) => {
  if (followUpMessages.length > 5) {
    viewContext.notification.show(
      'You can only add up to 6 Follow Ups',
      'error',
      true,
    );
    return;
  }
  if (
    !followUpMessages.at(-1) ||
    (followUpMessages.at(-1).message !== '' && followUpMessages.at(-1).number)
  ) {
    setFollowUpMessages([...followUpMessages, { ...BLANK_FOLLOW_UP }]);
  } else {
    viewContext.notification.show(
      'You are missing fields to complete',
      'error',
      true,
    );
  }
};

const checkFollowUps = (followUpMessages, position) => {
  return (
    position !== 1 ||
    (followUpMessages.at(-1).days !== 0 &&
      followUpMessages.at(-1).hours !== 0)
  );
};

const onChangeFollowUpInput = (form, i, followUpMessages, setFollowUpMessages) => {
  const auxFollowUpMessages = [...followUpMessages];
  auxFollowUpMessages[i][form.input] = form.value;
  setFollowUpMessages(auxFollowUpMessages);
};

const deleteFU = (i, followUpMessages, setFollowUpMessages) => {
  const auxFollowUpMessages = [...followUpMessages];
  auxFollowUpMessages.splice(i, 1);
  setFollowUpMessages(auxFollowUpMessages);
};

const onDeleteLinkedin = (item, linkedins, setLinkedins) => {
  const state = [...linkedins];
  const filtered = state.filter((x) => item !== x);
  setLinkedins(filtered);
};

const getAllAccounts = (accounts) => {
  let allAccounts = [];
  accounts.map((item) => {
    allAccounts.push({
      value: item.id,
      label: item.email,
    });
  });
  return allAccounts;
};

const getSalesOptions = (accounts) => {
  let salesOptions = [];
  for (let i = 0; i < accounts.length; i++) {
    if (accounts[i].salesNavigator === true) {
      salesOptions.push({
        value: accounts[i].id,
        label: accounts[i].email,
      });
    }
  }
  return salesOptions;
};

const formUpdater = (
  form,
  dispatch,
  setSalesNavAccount,
  setConnectionRequest,
  setLinkedins,
  campaignDetails,
  setCampaignDetails,
) => {
  if (
    form.input === 'search_url' &&
    form.value.includes('/sales/')
  ) {
    localStorage.setItem('SalesNav', true);
    dispatch(setSalesNavAccount(true));
  } else if (
    form.input === 'search_url' &&
    !form.value.includes('/sales/')
  ) {
    localStorage.setItem('SalesNav', false);
    dispatch(setSalesNavAccount(false));
  }

  if (form.input === 'connection_request_message') {
    setConnectionRequest(form.value);
  }

  if (form.input === 'linkedinAccount_ids') {
    const state = [];
    if (typeof form.value.value !== 'string') {
      form.value.forEach((item) => {
        state.push(item.value);
      });
      setLinkedins(state);
    } else {
      state.push(form.value.value);
      setLinkedins(state);
    }
  }
  const auxCampaignDetails = {
    ...campaignDetails,
  };
  auxCampaignDetails[form.input] = form.value;
  setCampaignDetails(auxCampaignDetails);
};

export { nextTab };
export { deleteFU };
export { addFollowUp };
export { formUpdater };
export { getAllAccounts };
export { checkFollowUps };
export { getSalesOptions };
export { onDeleteLinkedin };
export { onChangeFollowUpInput };
