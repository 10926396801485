const Style = {
  footer:
    'bg-slate-700 border-t border-solid border-slate-700 lg:px-24 xl:px-40 px-10 py-10',
  copyright: 'block opacity-70 text-left text-sm mb-3 text-white',
  address: 'block opacity-70 text-left text-sm not-italic text-white',
  nav: 'mb-5 flex md:flex-row flex-col',
  link: 'mr-4 text-sm no-underline hover:underline hover:text-blue-500 lg:text-lg text-white',
};

export default Style;
