import React, { useContext } from 'react';
import { AuthContext, Animate, Row, Card, Form, Link } from 'components/lib';

export function SignupDefault(props) {
  const context = useContext(AuthContext);

  return (
    <Animate type="pop">
      <Row>
        <p className="text-center pt-8 pb-1">Let's get you sorted</p>
        <h1 className="text-center font-bold text-4xl pb-12">
          Create your account
        </h1>
        <Card loading={false} restrictWidth center>
          <Form
            data={{
              name: {
                label: 'First Name',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name',
              },
              email: {
                label: 'Email',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
            }}
            url="/api/account"
            method="POST"
            buttonText="Create Account"
            callback={context.signin}
          />

          <div className="mt-4">
            Already registered? <Link url="/signin" text="Sign In" />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
