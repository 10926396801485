import { Icon } from "components/lib";

const SentOptionsModal = ({
  sendMessageEnter,
  setMessageEnter,
  sendMessageClick,
  setMessageClick,
  sendMessageTypeOptions,
  setSendMessageTypeOptions,
}) => {
  return (
    <div className="w-40 h-10 absolute bottom-10 right-3 overflow-hidden bg-white shadow-lg z-50 border border-slate-300  rounded-tl-xl rounded-tr-xl rounded-bl-xl">
      <ul className="modal-content flex flex-col justify-around h-full cursor-pointer">
        {!sendMessageClick && (
          <li
            className="flex items-center justify-start hover:bg-slate-200 h-full pl-2 text-sm cursor-pointer"
            onClick={() => {
              setMessageEnter(!sendMessageEnter);
              setMessageClick(!sendMessageClick);
              setSendMessageTypeOptions(!sendMessageTypeOptions);
            }}
          >
            <Icon image="mouse-pointer" size={15} className="mr-1" />
            Click to Send
          </li>
        )}
        {!sendMessageEnter && (
          <li
            className="flex items-center justify-start hover:bg-slate-200 h-full pl-2 text-sm cursor-pointers"
            onClick={() => {
              setMessageEnter(!sendMessageEnter);
              setMessageClick(!sendMessageClick);
              setSendMessageTypeOptions(!sendMessageTypeOptions);
            }}
          >
            <Icon image="corner-down-left" size={15} className="mr-1" />
            Press enter to send
          </li>
        )}
      </ul>
    </div>
  );
};

export default SentOptionsModal;
