import { Fragment, useState, useEffect, useContext } from 'react';
import { formData1, formDataUpdate } from 'views/campaigns/helpers/formData';
import { NewCampaignContext } from '../../contexts/campaigns/CampaignsContext';
import { useDispatch } from 'react-redux';
import { setAccounts, setSalesNavAccount, setSalesNavAccounts } from 'redux/features/linkedboostStore.js';
import {
  useAPI,
  Card,
  TabView,
  Button,
  TitleRow,
  useNavigate,
  Animate,
  ViewContext,
  useLocation,
} from 'components/lib';
import { paramsButtonsFollowUp } from "./helpers/paramsButtonsFollowUp";
import NewFollowUpForm from './components/newFollowUpForm';
import { updateCampaign } from './api/api';
import {
  nextTab, checkFollowUps, onChangeFollowUpInput,
  deleteFU, getAllAccounts, getSalesOptions, addFollowUp,
} from './helpers/functionHelpers';
import CampaignUpdate from "./components/CampaignUpdate";

export function UpdateCampaign() {
  const campaignId = useLocation().state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    campaignDetails, setCampaignDetails, followUpMessages, setFollowUpMessages,
    setFollowUpMessage, daysOfTheWeekSelected, setDaysOfTheWeekSelected,
  } = useContext(NewCampaignContext);
  const viewContext = useContext(ViewContext);
  const [index, setIndex] = useState(localStorage.getItem('AddCampaignTabs') ?? 0);
  const [linkedins, setLinkedins] = useState([]);
  const [connectionRequest, setConnectionRequest] = useState('');
  const list = useAPI('/api/list-all-linkedin-accounts');
  const buttons = ['First Name', 'Last Name', 'Company', 'Location'];

  useEffect(() => {
    if (list?.data) {
      dispatch(setAccounts(list.data));
      let allAccounts = getAllAccounts(list.data);
      let salesOptions = getSalesOptions(list.data);
      dispatch(setSalesNavAccounts(salesOptions));
      localStorage.setItem('salesAccounts', JSON.stringify(salesOptions));
      localStorage.setItem('linkedins', JSON.stringify(allAccounts));
    }
  }, [list.data, dispatch]);

  useEffect(() => { }, [followUpMessages]);
  useEffect(() => {
    if (linkedins)
      localStorage.setItem('SelectedAccounts', JSON.stringify(linkedins));
  }, [linkedins]);

  const onDeleteFU = (i) => deleteFU(i, followUpMessages, setFollowUpMessages);

  useEffect(() => localStorage.setItem('WorkingDays', JSON.stringify(daysOfTheWeekSelected)));

  return (
    <Fragment>
      <Animate type="slideup">
        <Card className="xl:mx-40 md:mx-10 mx-4 shadow-lg rounded-xl">
          <TabView
            name="AddCampaign"
            labels={['Campaign Details', 'Follow Up Messages']}
            currentIndex={index}
            onSwitch={(i) => setIndex(i)}
          >
            <Card>
              <CampaignUpdate
                formData1={formDataUpdate}
                nextTab={nextTab}
                index={index}
                checkFollowUps={checkFollowUps}
                viewContext={viewContext}
                setIndex={setIndex}
                followUpMessages={followUpMessages}
                setSalesNavAccount={setSalesNavAccount}
                setConnectionRequest={setConnectionRequest}
                setLinkedins={setLinkedins}
                campaignDetails={campaignDetails}
                setCampaignDetails={setCampaignDetails}
                connectionRequest={connectionRequest}
                linkedins={linkedins}
                daysOfTheWeekSelected={daysOfTheWeekSelected}
                setDaysOfTheWeekSelected={setDaysOfTheWeekSelected}
              />
            </Card>
            <div>
              <TitleRow className="flex py-4">
                <h1 className="text-2xl">Follow up messages</h1>
                <p className="text-slate-500">
                  Engage with your future customer
                </p>
              </TitleRow>

              <div className="flex justify-end w-full mt-10 lg:w-[97%] mb-4">
                <Button
                  text="+ Add Follow Up"
                  color="blue"
                  className="text-sm w-full md:w-1/3 lg:w-[200px] shadow-md"
                  action={() => addFollowUp(followUpMessages, setFollowUpMessages, viewContext)}
                />
              </div>

              <div
                className="flex flex-gap-6 flex-wrap"
                id="followup-container"
              >
                {followUpMessages.map((followUp, i) => (
                  <NewFollowUpForm
                    key={i}
                    onDelete={() => onDeleteFU(i)}
                    onChange={(form) => {
                      onChangeFollowUpInput(form, i, followUpMessages, setFollowUpMessages);
                    }}
                    {...followUp}
                    position={i}
                    instructions={paramsButtonsFollowUp(buttons, followUpMessages, setFollowUpMessages)}
                    followUpMessage={followUpMessages[i].message}
                    setFollowUpMessage={setFollowUpMessage}
                    followUpMessages={followUpMessages}
                    setFollowUpMessages={setFollowUpMessages}
                  />
                ))}
              </div>

              <div className="flex justify-end w-full mt-10 lg:w-[97%]">
                <Button
                  text="Cancel"
                  color="red"
                  className="w-full md:w-[200px] mr-2"
                  action={() => {
                    navigate('/campaigns');
                    localStorage.setItem("file", "[]");
                    campaignDetails.name = '';
                    campaignDetails.description = '';
                    campaignDetails.working_days = '';
                    campaignDetails.search_url = '';
                    campaignDetails.linkedinAccount_ids = '';
                    campaignDetails.connection_request_message = '';
                    campaignDetails.follow_up_messages = '';
                    campaignDetails.settings = '';
                    setFollowUpMessages([{
                      message: '',
                      number: '0',
                      timeframe: 'days',
                    }]);
                    setDaysOfTheWeekSelected(["1", "2", "3", "4", "5"]);
                    localStorage.setItem("AddCampaignTabs", "0");
                  }}
                />
                <Button
                  text="Update"
                  className="w-full md:w-[200px]"
                  action={() => updateCampaign(campaignId, followUpMessages, navigate)}
                />
              </div>
            </div>
          </TabView>
        </Card>
      </Animate>
    </Fragment>
  );
}
