import { useState } from "react";
import { useSelector } from "react-redux";
import axios from 'axios';
import { Animate } from "components/lib";
import Style from "./chatInput.tailwind";
import Send from "./send";

const ChatInput = ({ autoRes, createAutomatedResponse, conversation, leadId }) => {
  const fetchMessage = useSelector((state) => state.linkedboostStore.selectedMessage);
  const [sendMessageEnter, setMessageEnter] = useState(false);
  const [message, setMessage] = useState("");
  const user = JSON.parse(localStorage.getItem('user'));

  const sendMessage = (message) => {
    const URL = "/api/chat/send-message";
    const form = {
      lead_id: fetchMessage.lead_id,
      message: message,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.post(URL, form, config)
      .then(() => setMessage(''))
      .catch(() => alert("Something went wrong"));
  };

  return (
    <Animate>
      <form className={Style.form}>
        <textarea
          className={Style.textarea}
          placeholder="Write a message..."
          onInput={(e) => setMessage(e.target.value)}
          value={autoRes !== undefined ? autoRes : message}
          onKeyDown={(e) => {
            if (e.key === "Enter" && sendMessageEnter) {
              sendMessage(message);
              e.preventDefault();
              setMessage("");
            }
          }}
        />
        <Send
          createAutomatedResponse={createAutomatedResponse}
          conversation={conversation}
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          sendMessageEnter={sendMessageEnter}
          setMessageEnter={setMessageEnter}
        />
      </form>
    </Animate>
  );
};

export default ChatInput;
