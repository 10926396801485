import { Grid, Stat } from 'components/lib';
import Styles from './dashboardMetrics.tailwind';

const DashboardMetrics = ({ stats, storedStats }) => {
  return (
    <div className={Styles.container}>
      <Grid cols="4">
        <Stat
          loading={stats?.loading}
          value={storedStats.totalLeads}
          label="Sent Connections"
          icon="linkedin"
        />
        <Stat
          loading={stats?.loading}
          value={storedStats.totalAcceptedConnections}
          label="Accepted Connections"
          icon="user-check"
        />
        <Stat
          loading={stats?.loading}
          value={storedStats.totalPendingConnections}
          label="Pending Connections"
          icon="clock"
        />
        <Stat
          loading={stats?.loading}
          value={`${Math.round(
            storedStats.totalLeads
              ? ((storedStats.totalPendingConnections / storedStats.totalLeads) * 100).toFixed(2)
              : 0,
          )} %`}
          label="Success Ratio"
          icon="activity"
        />
      </Grid>
    </div>
  );
};

export default DashboardMetrics;
