import { useContext } from "react";
import { ViewContext, Card, Form, Icon } from "components/lib";
import { CSSTransition } from "react-transition-group";
import "./modalShowLead.scss";
import { useSelector } from "react-redux";
import noImage from "./null_pic.webp";

export function ModalShowLead(props) {
  const selectedLead = useSelector(
    (state) => state.linkedboostStore.selectedLead,
  );
  const context = useContext(ViewContext);
  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) =>
          e.target === e.currentTarget && context.modal.hide(true)
        }
      >
        <div className="modal-content bg-white">
          <div>
            <div className="flex justify-end">
              <div
                onClick={(e) => context.modal.hide(true)}
                className="border border-slate-400 shadow-md p-2 right-2 top-2 rounded-full absolute cursor-pointer hover:bg-slate-800 hover:text-white bg-white"
              >
                <Icon image="x" size={20} />
              </div>
            </div>
            <img
              className="h-[300px] w-full object-cover"
              src={selectedLead.profile_picture ? selectedLead.profile_picture : noImage}
            />

            <h1 className="text-left ml-8 mt-6 text-2xl mt-2 text-slate-700 font-bold italic">
              {selectedLead.first_name && selectedLead.last_name
                ? selectedLead.first_name + " " + selectedLead.last_name
                : "No name"}
            </h1>
            <h1 className="text-center text-lg text-slate-500 mt-2 px-8">
              {selectedLead.sub_title
                ? selectedLead.sub_title
                : "Software Developer"}
            </h1>
            <div className="flex items-center justify-start mt-4 ml-8">
              <Icon image="briefcase" size={20} className="mr-2" />
              <h1 className="text-left text-lg text-slate-700">
                {selectedLead.company_name
                  ? selectedLead.company_name
                  : "Good Software Dev"}
              </h1>
            </div>

            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="linkedin" size={20} className="mr-2 mb-1" />

              <a href="https://linkedin.com">
                <h1 className="text-left text-lg text-blue-600">
                  {selectedLead.linkedin_id
                    ? `linkedin.com/${selectedLead.linkedin_id}`
                    : "linkedin.com/unknown"}
                </h1>
              </a>
            </div>
            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="globe" size={20} className="mr-2" />
              <h1 className="text-left text-lg text-slate-700">
                {selectedLead.location ? selectedLead.location : "Unknown"}
              </h1>
            </div>
            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="activity" size={20} className="mr-2" />
              <h1 className="text-left text-lg text-slate-700">
                {selectedLead.status !== "connection_not_requested"
                  ? selectedLead.status.toUpperCase()
                  : "CONNECTION NOT REQUESTED"}
              </h1>
            </div>
            <div className="p-2 flex justify-end"></div>
            {props.form && (
              <Form
                method={props.method}
                url={props.url}
                data={props.form}
                destructive={props.destructive}
                buttonText={props.buttonText}
              />
            )}

            {props.text && (
              <div>
                <p className="mt-4 font-bold text-red-600 text-sm">*Note</p>
                <p className="text-left text-sm">{props.text}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
