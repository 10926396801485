import { TailSpin, ThreeDots } from 'react-loader-spinner';
import Styles from './linkedinLoader.tailwind';

const LinkedinLoader = () => (
  <div className={Styles.container}>
    <div className={Styles.form}>
      <div className={Styles.innerContainer}>
        <TailSpin
          height="150"
          width="150"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <h1 className={Styles.dotsContainer}>
          Please wait, we are validating your credentials 🪪
          <ThreeDots
            height="20"
            width="20"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </h1>
      </div>
    </div>
  </div>
);

export { LinkedinLoader };
