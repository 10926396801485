const replyBar = {
  data: {
    labels: [''],
    datasets: [
      {
        label: 'Total Leads',
        data: [0],
        borderColor: '#FFFFFF',
        backgroundColor: [
          '#8D8CC3',
          '#9A99CB',
          '#A6A5D7',
          '#B6B5E4',
          '#C5C4F1',
          '#CECDF8',
        ],
        hoverBorderColor: 'transparent',
      },
      {
        label: 'Requested Connections',
        data: [0],
        borderColor: '#FFFFFF',
        backgroundColor: [
          '#8D8CC3',
          '#9A99CB',
          '#A6A5D7',
          '#B6B5E4',
          '#C5C4F1',
          '#CECDF8',
        ],
        hoverBorderColor: 'transparent',
      },
      {
        label: 'Accepted Connections',
        data: [0],
        borderColor: '#FFFFFF',
        backgroundColor: [
          '#8D8CC3',
          '#9A99CB',
          '#A6A5D7',
          '#B6B5E4',
          '#C5C4F1',
          '#CECDF8',
        ],
        hoverBorderColor: 'transparent',
      },
      {
        label: 'Pending Connections',
        data: [0],
        borderColor: '#FFFFFF',
        backgroundColor: [
          '#8D8CC3',
          '#9A99CB',
          '#A6A5D7',
          '#B6B5E4',
          '#C5C4F1',
          '#CECDF8',
        ],
        hoverBorderColor: 'transparent',
      },
    ],
  },
  loading: false,
};

const overallMetricsHelper = (overallMetrics, overallObjectives) => {
  return ({
    data: [
      {
        label: overallObjectives.leads_created + ' leads created',
        value: `${100 * (overallMetrics.data.totalLeads / overallObjectives.leads_created)}%`,
      },
      {
        label: overallObjectives.requested_connections + ' Requested Connections',
        value: `${100 * (overallMetrics.data.totalRequestedConnections / overallObjectives.requested_connections)
          }%`,
      },
      {
        label: overallObjectives.accepted_connections + ' Accepted Connections',
        value: `${100 * (overallMetrics.data.totalAcceptedConnections / overallObjectives.accepted_connections)
          }%`,
      },
    ],
  });
};

const overallChartState = {
  data: {
    labels: [
      'No data yet',
    ],
    datasets: [
      {
        label: 'User',
        data: [
          1,
        ],
        borderColor: '#FFFFFF',
        backgroundColor: [
          '#8D8CC3',
          '#9A99CB',
          '#A6A5D7',
          '#B6B5E4',
          '#C5C4F1',
          '#CECDF8',
        ],
        hoverBorderColor: 'transparent',
      },
    ],
  },
  loading: false,
};

const overallChartHelper = (overallMetrics) => {
  return (
    {
      data: {
        labels: [
          'Total Leads',
          'Requested Connections',
          'Accepted Connections',
        ],
        datasets: [
          {
            label: 'User',
            data: [
              overallMetrics.data.totalLeads,
              overallMetrics.data.totalRequestedConnections,
              overallMetrics.data.totalAcceptedConnections,
            ],
            borderColor: '#FFFFFF',
            backgroundColor: [
              '#8D8CC3',
              '#9A99CB',
              '#A6A5D7',
              '#B6B5E4',
              '#C5C4F1',
              '#CECDF8',
            ],
            hoverBorderColor: 'transparent',
          },
        ],
      },
      loading: false,
    }
  );
};

const filterOverallFormData = (selectOptions) => {
  return ({
    campaign: {
      label: 'Select a Campaign',
      type: 'select',
      options: selectOptions.length ? selectOptions : [{ label: 'No campaigns yet, add one...', value: 'No campaigns yet, add one...' }],
    },
  }
  );
};

const defaultObjectives = {
  leads_created: 50,
  requested_connections: 50,
  accepted_connections: 10,
};

const filterOverallOnChangeFunction = (
  e,
  stats,
  setOverallModal,
  setProgressTitle,
  setChartTitle,
  setOverallChartState,
  setOverallMetricsState,
  overallMetricsState,
  overallMetrics,
) => {
  const selectedCampaign = stats?.campaignsInfo?.find(item => item.campaignId === e.value);
  if (e.value !== 'unselected' && e.value !== 'No campaigns yet, add one...') {
    setOverallModal(false);
    setProgressTitle(`${selectedCampaign?.campaignName} Objectives`);
    setChartTitle(`${selectedCampaign?.campaignName} Leads`);
    setOverallMetricsState({
      data: [
        {
          label: `20 leads created`,
          value: `${100 * (selectedCampaign?.leads / 20)}%`,
        },
        {
          label: '20 Requested Connections',
          value: `${100 * (selectedCampaign?.requestedConnections.total / 20)
            }%`,
        },
        {
          label: '5 Accepted Connections',
          value: `${100 * (selectedCampaign?.acceptedConnections.total / 5)
            }%`,
        },
      ],
    });
    setOverallChartState({
      data: {
        labels: [
          'Total Leads',
          'Total Requested Connections',
          'Accepted Connections',
        ],
        datasets: [
          {
            label: 'User',
            data: [
              selectedCampaign?.leads ? selectedCampaign.leads : 0,
              selectedCampaign?.requestedConnections.total ? selectedCampaign.requestedConnections.total : 0,
              selectedCampaign?.acceptedConnections ? selectedCampaign.acceptedConnections.total : 0,
              selectedCampaign?.pendingConnections ? selectedCampaign.pendingConnections.total : 0,
            ],
            borderColor: '#FFFFFF',
            backgroundColor: [
              '#8D8CC3',
              '#9A99CB',
              '#A6A5D7',
              '#B6B5E4',
              '#C5C4F1',
              '#CECDF8',
            ],
            hoverBorderColor: 'transparent',
          },
        ],
      },
      loading: false,
    });
  } else {
    setOverallModal(true);
    setProgressTitle('Overall Objectives');
    setChartTitle('Overall Leads');
    setOverallMetricsState({
      data: [
        {
          label: '50 leads created',
          value: `${100 * (overallMetrics.data.totalLeads / 50)}%`,
        },
        {
          label: '50 Requested Connections',
          value: `${100 * (overallMetrics.data.totalRequestedConnections / 50)
            }%`,
        },
        {
          label: '10 Accepted Connections',
          value: `${100 * (overallMetrics.data.totalAcceptedConnections / 10)
            }%`,
        },
      ],
    });
  }
};

export {
  replyBar,
  overallMetricsHelper,
  overallChartState,
  overallChartHelper,
  filterOverallFormData,
  defaultObjectives,
  filterOverallOnChangeFunction,
};
