import { FormFollowUp, Icon } from 'components/lib';
import React from 'react';

const NewFollowUpForm = ({
  number,
  message,
  onChange,
  onDelete,
  position,
  instructions,
  followUpMessage,
  setFollowUpMessage,
  followUpMessages,
  setFollowUpMessages,
}) => {
  return (
    <div className="flex flex-col w-full rounded-md bg-white static lg:px-20">
      <div className="absolute right-6" onClick={onDelete}>
        {position !== 0 && (
          <Icon
            image="trash"
            color="dark"
            size={20}
            className="z-10 cursor-pointer hover:scale-125 hover:text-red-500 m-2"
          />
        )}
      </div>
      <div className="flex">
        <div className="w-[15%] hidden md:hidden lg:inline">
          <div className="p-6 border border-slate-200 text-xl flex justify-center items-center rounded-xl bg-slate-100 shadow-sm">
            <h1 className="text-center text-sm font-bold">
              {(position === 0 && 'Start') ||
                (position === 1
                  ? '2nd Message'
                  : position === 2
                    ? '3rd Message'
                    : position > 2 && position + 1 + 'th Message')}
            </h1>
          </div>
          {followUpMessages.length > 1 && (
            <div className="h-48 w-1/2 border-r-2 border-slate-300"></div>
          )}
        </div>
        <div className="absolute left-10 mt-2 px-1 rounded-lg lg:hidden text-slate-500 text-sm">
          {(position === 0 && 'Start') ||
            (position === 1
              ? '2nd Message'
              : position === 2
                ? '3rd Message'
                : position > 2 && position + 1 + 'th Message')}
        </div>
        <div className="lg:w-[80%] lg:ml-6 lg:mr-10 w-full">
          <FormFollowUp
            className="flex flex-wrap flex-gap-[1%] flex-between md:px-6 pt-4 bg-slate-100 pt-8 shadow-lg pt-10 rounded-xl"
            data={{
              message: {
                type: 'textFollowUp',
                placeholder: 'Write a message',
                required: true,
                containerClass: 'w-full',
                class: 'h-20',
                value: message,
              },
              number: {
                type: 'numberFollowUp',
                value: number,
                placeholder: 'Send after ?',
                min: 0,
                containerClass: position === 0 ? 'hidden' : 'flex w-11/12 h-8',
              },
              timeframe: {
                type: 'selectFollowUp',
                options: [{ value: 'days', label: 'Days' }],
                default: 'Days',
                containerClass:
                  position === 0
                    ? 'hidden'
                    : 'flex w-1/12 w-1/12 flex items-center rounded-lg h-8 bg-white',
              },
            }}
            updateOnChange
            onChange={onChange}
            labels={false}
            instructions={instructions}
            followUpMessage={followUpMessage}
            setFollowUpMessage={setFollowUpMessage}
            followUpMessages={followUpMessages}
            setFollowUpMessages={setFollowUpMessages}
          />
        </div>
      </div>
    </div>
  );
};

export default NewFollowUpForm;
