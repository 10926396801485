import React, { useState, useEffect } from "react";
import { Label, Error, Icon, ClassHelper } from "components/lib";
import Style from "./input.tailwind.js";

export function TextInputFollowUp(props) {
  const error = props.errorMessage || "Please enter a value";
  const [content, setContent] = useState("");
  function validate(e) {
    let value = e ? e.target.value : "";
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === "") valid = false;

    // input isn't required and value is blank
    if (!props.required && value === "") valid = true;

    if (props.required && value !== "") valid = true;

    // update the parent form
    props.onChange?.(props.name, value, valid);
  }

  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    containerClass: props.containerClass,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });
  const handleContentChange = (e) => {
    if (props.followUpMessage.length === 1) {
      setContent("");
    } else {
      setContent(props.followUpMessage);
    }
  };
  useEffect(() => {
    if (props.followUpMessage) {
      setContent(props.followUpMessage);
    }
  }, [props.followUpMessage]);

  return (
    <div className={Style.input + " " + props.containerClass}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      {props.type === "textFollowUp" ? (
        <div>
          <textarea
            id={props.id}
            name={props.name}
            value={props.followUpMessage}
            className="border border-slate-100 rounded-md h-28 text-md p-3 text-slate-500 w-full absolute bottom-0 left-0 top-0 opacity-50"
            // onChange={(e) =>
            //   props.onChange?.(props.name, e.target.value, undefined)
            // }
            onChange={(e) => {
              if (e.target.value.length === 1 && e.target.value === " ") {
                props.setFollowUpMessage("");
              } else if (e.target.value.includes("  ")) {
                let string = e.target.value;
                let result = string.replace(/  +/g, " ");
                props.onChange?.(props.name, result, undefined);
              } else {
                props.onChange?.(props.name, e.target.value, undefined);
              }
            }}
            onBlur={(e) => validate(e)}
            onKeyDown={(e) => {
              // if (e.key === " " && !e.target.value.length) {
              //   e.preventDefault();
              // }

              if (e.key === "Enter") {
                e.preventDefault();
              }
              if (props.followUpMessage.includes("{{" || "}}")) {
                const message =
                  props.followUpMessages[props.followUpMessages.length - 1]
                    .message;
                if (
                  e.key === "Backspace" &&
                  message[message.length - 1] === "}"
                ) {
                  let state = props.followUpMessage.split(/\s+/);
                  const result = state.slice(0, -1).join(" ");
                  // if (props.followUpMessages)
                  props.setFollowUpMessages([
                    { message: result + " ", number: "0", timeframe: "days" },
                  ]);
                }
              }
            }}
            onInput={handleContentChange}
          ></textarea>
          <div
            className="border border-slate-300 rounded-md h-28 text-md p-3 text-slate-500 bg-white"
            contentEditable="true"
            onInput={handleContentChange}
            suppressContentEditableWarning={true}
          >
            {content.split(/(\{\{.*?\}\})/).map((word, index) => {
              if (
                word.includes("{{first_name}}") ||
                word.includes("{{last_name}}") ||
                word.includes("{{company}}") ||
                word.includes("{{location}}")
              ) {
                return word === "{{first_name}}" ||
                  word === "{{last_name}}" ||
                  word === "{{company}}" ||
                  word === "{{location}}" ? (
                  <span
                    key={index}
                    className="bg-purple-500 text-slate-200 rounded-lg shadow-lg z-10 mb-4"
                  >
                    <p className="inline text-purple-50">{word.slice(0, 2)}</p>
                    <p className="inline text-slate-900">{word.slice(2, -2)}</p>
                    <p className="inline text-purple-50">{word.slice(-2)}</p>
                  </span>
                ) : (
                  word
                );
              } else {
                return word;
              }
            })}

            {content.length === 0 && (
              <p className="inline text-slate-600">
                Enter a friendly followup message here
              </p>
            )}
          </div>
        </div>
      ) : (
        <input
          type="text"
          id={props.id}
          name={props.name}
          value={props.value || ""}
          className={textStyle}
          placeholder={props.placeholder}
          onChange={(e) =>
            props.onChange?.(props.name, e.target.value, undefined)
          }
          onBlur={(e) => validate(e)}
          onKeyDown
        />
      )}

      {props.valid === false && <Error message={error} />}

      {props.valid === true && (
        <Icon
          image="check"
          color="#8CC57D"
          className={Style.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
