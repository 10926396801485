const Styles = {
  container: `md:bg-gradient-to-r md:from-blue-500 md:to-purple-500 bg-gradient-to-b from-blue-500 to-purple-500
  md:px-14 lg:px-24 xl:px-40 w-full break-words md:h-[45em] py-14 md:py-0 px-6 rounded-b-3xl border-b-[1em] border-blue-900 md:flex`,
  heroLeft: "flex flex-col md:w-[65%] justify-center",
  heroTitle: `text-[3.66em] font-bold leading-[0.95em] text-white w-full mb-10 md:text-start lg:text-[5.8em] xl:text-[6.5em] 
  lg:leading-[0.9em] xl:leading-[0.8em] text-clip overflow-hidden`,
  heroSpan: "text-green-500 ml-1",
  subtitle: "pl-1 mb-6 text-slate-200 w-full text-center md:text-left md:text-xl pb-2 xl:text-2xl xl:pb-4",
  list: "pl-6 hidden md:block md:pr-5 w-[90%] md:pl-10 lg:pl-6",
  listItem: "list-disc text-white md:text-lg xl:text-xl xl:mb-1",
  button: "inline-block lg:w-36 w-full bg-black rounded-full hover:bg-[#22C55E] hover:text-slate-600 text-lg md:hidden",
  mobileSubtitle: "text-center text-slate-100 text-sm mt-4 md:hidden",
  mobileSignUp: "hidden md:w-1/2 lg:w-[40%] md:flex md:items-center md:justify-end lg:pl-0 xl:w-[35%] hover:scale-105 ease-out duration-200",
};

export default Styles;
