import { Fragment, useContext } from "react";
import { Form, ViewContext, useNavigate } from "components/lib";
import Styles from "./revalidateForm.tailwind";

const RevalidateForm = ({ loading }) => {
  const context = useContext(ViewContext);
  const navigate = useNavigate();

  return (
    <Fragment>
      {!loading && (
        <div>
          <p className={Styles.noteTitle}>NOTE*</p>
          <p className={Styles.note}>
            A verification has been sent to your email or phone 🎉 🎉 Please
            enter the code below to validate the account.
          </p>
          <Form
            data={{
              linkedinAccountId: {
                label: 'user id',
                type: 'hidden',
                value: localStorage.getItem('linkedinAccountId')
                  ? localStorage.getItem('linkedinAccountId')
                  : 'Value not found',
              },
              authenticationCode: {
                label: 'Code',
                type: 'text',
                required: true,
                placeholder: 'Enter code',
              },
            }}
            buttonText="Validate Account"
            url="/api/submit-auth-code-linkedin"
            method="POST"
            callback={(res) => {
              if (res.data.data.status === 'active') {
                context.notification.show(
                  'Your Linkedin Account has successfully being updated!',
                  'success',
                  true,
                );
                navigate("/linkedins");
              }
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default RevalidateForm;
