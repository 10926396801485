import { Fragment } from "react";
import { TailSpin, ThreeDots } from 'react-loader-spinner';
import Styles from "./revalidateLoader.tailwind";

const RevalidateLoader = ({ loading }) => (
  <Fragment>
    {loading && (
      <div className={Styles.container}>
        <TailSpin
          height="150"
          width="150"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <h1 className={Styles.title}>
          Please wait, we are revalidating your credentials 🪪
          <ThreeDots
            height="20"
            width="20"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </h1>
      </div>
    )}
  </Fragment>
);

export default RevalidateLoader;
