export function paramsButtonsFollowUp(buttons, followUpMessages, setFollowUpMessages) {
  const res = buttons.map((button) => {
    return (
      <div
        key={button}
        className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded w-1/4 text-center ml-1 text-sm"
        onClick={(e) => {
          let state = followUpMessages.at(-1).message;
          if (e.target.innerText.includes(' ')) {
            let split = e.target.innerText.split(' ');
            split[0] = split[0].toLowerCase();
            split[1] = split[1].toLowerCase();
            state += `{{${split[0] + '_' + split[1]}}}`;
            followUpMessages.at(-1).message = state;
            setFollowUpMessages([...followUpMessages]);
          } else {
            let locationState = e.target.innerText.toLowerCase();
            state += `{{${locationState}}}`;
            followUpMessages.at(-1).message = state;
            setFollowUpMessages([...followUpMessages]);
          }
        }}
      >
        {button}
      </div>
    );
  });
  return res;
}

