const CreateGraphData = (arr) => {
  const acceptedConnections = arr[0].acceptedConnections.data ?? null;
  const pendingConnections = arr[0].pendingConnections.data ?? null;
  const requestedConnections = arr[0].requestedConnections.data ?? null;

  let acceptedConnectionsData = [];
  if (acceptedConnections != null && acceptedConnections.length > 0) {
    for (let i = 0; i < 12; i++) {
      if (i === acceptedConnections[0].month) {
        acceptedConnectionsData.push(acceptedConnections[0].amount);
      } else {
        acceptedConnectionsData.push(0);
      }
    }
  } else {
    acceptedConnectionsData = Array(12).fill(0);
  }

  let pendingConnectionsData = [];
  if (pendingConnections !== null && pendingConnections.length > 0) {
    for (let i = 0; i < 12; i++) {
      if (i === pendingConnections[0].month) {
        pendingConnectionsData.push(pendingConnections[0].amount);
      } else {
        pendingConnectionsData.push(0);
      }
    }
  } else {
    pendingConnectionsData = Array(12).fill(0);
  }

  let requestedConnectionsData = [];
  if (requestedConnections !== null && requestedConnections.length > 0) {
    for (let i = 0; i < 12; i++) {
      if (i === requestedConnections[0].month) {
        requestedConnectionsData.push(requestedConnections[0].amount);
      } else {
        requestedConnectionsData.push(0);
      }
    }
  } else {
    requestedConnectionsData = Array(12).fill(0);
  }

  return {
    acceptedConnectionsData,
    pendingConnectionsData,
    requestedConnectionsData,
  };
};

export default CreateGraphData;
