import { useContext, useState } from 'react';
import { Card, Table, ViewContext, useNavigate, ModalShowAccount } from "components/lib";
import { useDispatch } from 'react-redux';
import { LinkedinLoader } from '../linkedinLoader/linkedinLoader';
import {
  deleteLinkedinAccount,
  badges,
  navigateAction,
  editAction,
  invalidCredentialsAction,
  verify2faAction,
  verifyNewAction,
  verifiedAccountMessageAction,
} from '../../helpers/helpers';
import Styles from "./linkedinsTable.tailwind";

const LinkedInsTable = ({ linkedins, list }) => {
  const context = useContext(ViewContext);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auxLinkedins = linkedins.map((item) => {
    return {
      id: item.id,
      email: item.email,
      status: item.status,
      date_created: item.date_created,
      name: item.name,
    };
  });

  return (
    <>
      <Card className={Styles.container}>
        <Table
          search
          className={Styles.table}
          data={auxLinkedins}
          loading={list.loading}
          show={['name', 'email', 'status', 'date_created']}
          actions={{
            delete: (e) => deleteLinkedinAccount(e, context, dispatch),
            custom: [
              navigateAction(linkedins, setSelectedAccount, modal, setModal),
              editAction(setLoading, context),
              invalidCredentialsAction(navigate, context),
              verify2faAction(navigate, context),
              verifyNewAction(navigate, context),
              verifiedAccountMessageAction(navigate, context),
            ],
          }}
          badge={badges}
        />

        {modal && (
          <ModalShowAccount
            {...selectedAccount}
            modal={modal}
            setModal={setModal}
          />
        )}

      </Card>
      {loading && <LinkedinLoader />}
    </>
  );
};

export default LinkedInsTable;
