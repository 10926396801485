const users = [
  {
    id: 1,
    name: "Emma Johnson",
    campaign: "Adidas America",
    details: {
      position: "Marketing Manager / Adidas North America",
      company: "Adidas",
      email: "ejohnson@adidas.com",
      linkedin: "/emma-johnson-123/",
      phone: "123-456-7890",
      location: "Portland, Oregon",
    },
    image: "https://writestylesonline.com/wp-content/uploads/2018/11/Three-Statistics-That-Will-Make-You-Rethink-Your-Professional-Profile-Picture.jpg",
    messages: [
      { content: "Hi, I wanted to discuss our upcoming marketing campaign. Are you available?", time: "12:01PM", lead: false },
      { content: "Let me check my schedule.", time: "2:43 PM", lead: true },
      { content: "Sure, take your time. Just let me know when youre available.", time: "2:45 PM", lead: false },
      { content: "Im free after 4 PM. Does that work for you?", time: "3:12 PM", lead: true },
      { content: "That works for me. Lets meet in the conference room.", time: "3:15 PM", lead: false },
      { content: "Great! Ill see you there at 4 PM.", time: "3:17 PM", lead: true },
      { content: "Before the meeting, could you prepare a brief overview of our target audience and key messaging?", time: "3:20 PM", lead: false },
    ],
  },
  {
    id: 2,
    name: "Michael Smith",
    campaign: "Nike",
    details: {
      position: "Marketing Manager / Nike",
      company: "Adidas",
      email: "michaelsmith@nike.com",
      linkedin: "/mSmith-123/",
      phone: "123-456-987",
      location: "Beaverton, Oregon",
    },
    image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSw7AdR-r7L5sQBX45nDQNkCnlpV70I3pPQ--Pu16fZ8mncpQFa",
    messages: [
      { content: 'Hi, I wanted to discuss our upcoming marketing campaign. Are you available?', time: '12:01PM', lead: false },
      { content: 'No! Lets set up a meeting to go over the details next week.', time: '3:04 PM', lead: true },
    ],
  },
  {
    id: 3,
    name: "Alex Wilson",
    campaign: "Puma",
    details: {
      position: "Chief Markering Officer at Puma",
      company: "Puma",
      email: "alexwilson@npuma.com",
      linkedin: "/wilson-alex-123/",
      phone: "123-456-284",
      location: "Germany",
    },
    image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTjYjDB3IqghVeFgpgWOdnJH3VgYP4coIqzAsBpXoSYMRfplaif",
    messages: [
      { content: 'Hi, I wanted to discuss our upcoming marketing campaign. Are you available?', time: '12:01PM', lead: false },
      { content: 'Great, Lets meet today.', time: '1:30 PM', lead: true },
    ],
  },
  {
    id: 4,
    name: "Sophia Anderson",
    campaign: "New Balance",
    details: {
      position: "Marketing Manager / New Balance",
      company: "New Balance",
      email: "sophiaanderson@nb.com",
      linkedin: "/sophia-anderson-123/",
      phone: "123-456-7890",
      location: "New, york, New York",
    },
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4EBTLtJgejFgPs2aNbzAj5ghCI3bMwwS2mGl5vG0DzJXbMY8l",
    messages: [
      { content: 'Hi, I wanted to discuss our upcoming marketing campaign. Are you available?', time: '12:01PM', lead: false },
      { content: 'Sorry, Im not interested', time: '6:04 AM', lead: true },
    ],
  },
  {
    id: 5,
    name: "John Thompson",
    campaign: "Reebok",
    details: {
      position: "Marketing Manager / Reebok",
      company: "Reebok",
      email: "jthompson@reebok.com",
      linkedin: "/john-thompson-123/",
      phone: "123-456-7890",
      location: "California",
    },
    image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcShXv7t_KjPkSwMegOKcJZtbRDJrpMwhkSugNj7VQLJZcb90LqX",
    messages: [
      { content: 'Hi, I wanted to discuss our upcoming marketing campaign. Are you available?', time: '12:01PM', lead: false },
      { content: 'Let me check my schedule.', time: '12:30 PM', lead: true },
      { content: 'Ok, got you, no rush at all! Have a great day', time: '12:32PM', lead: false },
      { content: 'Sure, thanks!', time: '1:00 PM', lead: true },
    ],
  },
];

const messagesList = [
  {
    user: "You",
    message: "Hey Sarah, I noticed your recent marketing campaign on LinkedIn. It's impressive!",
    time: "12:01PM",
  },
  {
    user: "Sarah",
    message: "Thanks, John! I put a lot of effort into it. I'm glad you liked it!",
    time: "12:04PM",
  },
  {
    user: "You",
    message: "Hey Sarah, I noticed your recent marketing campaign on LinkedIn. It's impressive!",
    time: "12:06PM",
  },
  {
    user: "Sarah",
    message: "Thanks, John! I put a lot of effort into it. I'm glad you liked it!",
    time: "12:06PM",
  },
  {
    user: "You",
    message: "Absolutely! The way you presented the content and engaged the audience was commendable.",
    time: "12:07PM",
  },
  {
    user: "Sarah",
    message: "I appreciate your kind words, John. Your marketing campaigns have always inspired me.",
    time: "12:08PM",
  },
  {
    user: "John",
    message: "Thank you, Sarah! Collaboration between marketers like us is crucial for driving industry growth.",
    time: "12:08PM",
  },
];

export { users, messagesList };
