const HowItWorks = () => (
  <div className="py-14 lg:py-32">
    <h1 className="mb-1 lg:mb-4 text-center text-2xl lg:text-4xl font-bold text-slate-700 tracking-tight">
      How Linkedboost works
    </h1>
    <p className="text-center text-xl lg:text-2xl mb-4 lg:mb-10 tracking-tight">
      Get started in 3 easy steps
    </p>
    <div className="flex px-8 lg:px-28 gap-6 flex-col lg:flex-row items-center">
      <div className="flex flex-col flex-1 items-center md:w-2/3 border-b border-slate-400 rounded-xl md:border-none">
        <div className="h-60 md:h-80 flex">
          <img
            className="object-cover shadow-lg rounded-xl border border-slate-200"
            src={require('./images/howItworks1.png')}
            alt=""
          />
        </div>
        <h1 className="text-center tracking-tight font-bold pt-6 pb-4 text-2xl text-slate-700">
          1. Add your accounts
        </h1>
        <p className="text-center text-lg mb-6">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste quidem
          at possimus libero exercitationem quasi aliquam, voluptatem expedita
        </p>
      </div>
      <div className="flex flex-col flex-1 items-center md:w-2/3 border-b border-slate-400 rounded-xl md:border-none">
        <div className="h-60 md:h-80 flex">
          <img
            className="object-cover shadow-lg rounded-xl border border-slate-200"
            src={require('./images/howItworks2.png')}
            alt=""
          />
        </div>
        <h1 className="text-center tracking-tight font-bold pt-6 pb-4 text-2xl text-slate-700">
          2. Create targeted campaigns
        </h1>
        <p className="text-center text-lg mb-6">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste quidem
          at possimus libero exercitationem quasi aliquam, voluptatem expedita
        </p>
      </div>
      <div className="flex flex-col flex-1 items-center md:w-2/3 border-b border-slate-400 rounded-xl md:border-none">
        <div className="h-60 md:h-80 flex">
          <img
            className="object-cover shadow-lg rounded-xl border border-slate-200"
            src={require('./images/howItworks3.png')}
            alt=""
          />
        </div>
        <h1 className="text-center tracking-tight font-bold pt-6 pb-4 text-2xl text-slate-700">
          3. Automate growth
        </h1>
        <p className="text-center text-lg mb-6">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste quidem
          at possimus libero exercitationem quasi aliquam, voluptatem expedita
        </p>
      </div>
    </div>
  </div>
);

export default HowItWorks;
