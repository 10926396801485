import { useContext } from "react";
import { ViewContext, FormUpdateLinkedin, Icon } from "components/lib";
import { CSSTransition } from "react-transition-group";
import updateAccountImage from "./updateAccountImage.avif";
import "./modal.scss";

export function ModalUpdateAccount(props) {
  const context = useContext(ViewContext);

  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) =>
          e.target === e.currentTarget && context.modal.hide(true)
        }
      >
        <div className="modal-content absolute shadow-lg">
          <div className="flex w-[650px] relative right-20 bg-black overflow-hidden">
            <img
              className="object-cover w-1/3 scale-105"
              src={updateAccountImage}
            />
            <div className="bg-white p-8 w-2/3">
              <div
                onClick={(e) => context.modal.hide(true)}
                className="shadow-md p-2 right-2 top-2 rounded-full absolute cursor-pointer hover:bg-slate-800 hover:text-white"
              >
                <Icon image="x" size={20} />
              </div>
              <h1 className="text-2xl text-center text-slate-900 py-4 mb-4 tracking-wider mt-4">
                {props.title}
              </h1>
              <p className="m-0 p-0 text-red-600 font-bold">NOTE*</p>
              {props.text && <p className="italic">{props.text}</p>}
              {props.form && (
                <FormUpdateLinkedin
                  method={props.method}
                  url={props.url}
                  data={props.form}
                  destructive={props.destructive}
                  buttonText={props.buttonText}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
