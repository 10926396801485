import { useAPI, Table, Card, Grid, Stat, useNavigate } from "components/lib";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Details from "views/campaigns/components/details";

const CampaignDetails = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const WEEKDAYS = ['MON', ', TUE', ', WED', ', THU', ', FRI', ', SAT', ', SUN'];
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [campaignUrl, setCampaignUrl] = useState('');
  const [repliedLeads, setRepliedLeads] = useState(0);
  const [pendingLeads, setPendingLeads] = useState(0);
  const list = useAPI("/api/list-all-campaigns");
  const leads = useAPI(`/api/list-all-leads-from-campaign/${state.state}`);
  const referencedAccount = useSelector((state) => state.linkedboostStore.campaignDetailsLinkedinAccount);
  const editCampaign = () => navigate(`/campaigns/update/${state.state}`);

  console.log(state);

  useEffect(() => {
    if (leads.data?.length) {
      const repliedLeads = leads.data.filter((item) => item.status === "replied");
      const pendingLeads = leads.data.filter((item) => item.status === "connection_not_requested");
      setRepliedLeads(repliedLeads.length);
      setPendingLeads(pendingLeads.length);
    }
  }, [leads.data]);

  useEffect(() => {
    if (list.data) setCampaigns(list.data);
    if (campaigns.length > 0) {
      const selectedCampaign = campaigns.filter((item) => item.id === state.state);
      setCampaignUrl(selectedCampaign[0].search_url);
      const weekdays = [];
      for (let i = 0; i <= selectedCampaign[0].work_week_days.length - 1; i++) {
        weekdays.push(WEEKDAYS[selectedCampaign[0].work_week_days[i] - 1]);
      }

      const aux = selectedCampaign.map((item) => {
        return {
          name: item.name,
          status: item.status,
          date_created: item.date_created,
          // description: item.description,
          starting_hour: item.start_work_hour,
          ending_hour: item.end_work_hour,
          workDays: weekdays,
        };
      });
      setSelectedCampaign(aux);
    }
  }, [list.data, campaigns]);

  const navigateToLeads = () => {
    const string = window.location.pathname;
    const array = string.split('/');
    navigate(`/campaigns/leads/${array[array.length - 1]}`, { state: array[array.length - 1] });
  };

  return (
    <>
      {list.data?.length > 0 && (
        <Grid cols="4">
          <Stat loading={leads.data?.loading} value={leads.data?.length} label="Leads Created" icon="linkedin" />
          <Stat loading={leads.data?.loading}
            value={leads.data?.length ? repliedLeads : leads.data?.length}
            label="Replied Connections"
            icon="user-check"
          />
          <Stat
            loading={leads.data?.loading}
            value={leads.data?.length ? pendingLeads : leads.data?.length}
            label="To Be Sent Connections"
            icon="clock"
          />
          <Stat
            loading={leads.data?.loading}
            value={leads.data?.length ? `${Math.abs(Math.round(
              leads.data?.length
                ? (((pendingLeads / leads.data?.length) * 100) - 100).toFixed(2)
                : 0,
            ))} %` : '0 %'}
            label="Success Ratio"
            icon="activity"
          />
        </Grid>
      )}
      <Card>
        <Table
          data={selectedCampaign}
          actions={{
            edit: editCampaign,
            custom: [
              {
                icon: 'users',
                action: navigateToLeads,
                condition: {
                  col: 'status',
                  value: "running",
                },
              },
              {
                icon: 'link',
                action: () => window.open(campaignUrl),
                condition: {
                  col: 'status',
                  value: "running",
                },
              },
            ],
          }}
          badge={{
            col: "status",
            color: "blue",
            condition: [
              { value: "running", color: "green" },
              { value: "paused", color: "orange" },
              { value: "finished", color: "blue" },
            ],
          }}
        />
      </Card>
      {referencedAccount?.length === 1 &&
        <div className="flex gap-4 flex-wrap justify-between">
          <div className="bg-white mt-4 rounded-lg w-full md:w-[49%] p-4 shadow-lg border">
            <Details data={referencedAccount[0]} title="Referenced Account" />
          </div>
          <div className="hidden lg:flex bg-slate-50 mt-4 rounded-lg w-1/2 p-4 md:w-[49%] border-dashed border-2 border-slate-300" />
        </div>
      }
    </>
  );
};

export { CampaignDetails };
