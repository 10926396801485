import React, { useContext } from 'react';
import { AuthContext, Animate, Form, Link } from 'components/lib';

export function Signup() {
  const context = useContext(AuthContext);

  return (
    <Animate type="slideup">
      <div className="flex flex-col justify-center items-center px-8 pb-4 pt-6 rounded-3xl bg-white shadow-lg w-full xl:h-full border-r-8 border-slate-900">
        <p className="text-center pb-1 lg:text-lg xl:text-xl">Interested?</p>
        <h1 className="text-center font-bold text-2xl lg:text-3xl mb-4 xl:mb-8">
          Create an account!
        </h1>
        <Form
          data={{
            name: {
              label: 'First Name',
              type: 'text',
              required: true,
              errorMessage: 'Please enter your first name',
            },
            email: {
              label: 'Email',
              type: 'email',
              required: true,
            },
            password: {
              label: 'Password',
              type: 'password',
              required: true,
              complexPassword: true,
            },
            confirm_password: {
              type: 'hidden',
              value: null,
            },
          }}
          url="/api/account"
          method="POST"
          buttonText="Create account"
          callback={context.signin}
          className="w-5/6"
        />

        <div className="mt-4 xl:mt-6">
          Already registered? <Link url="/signin" text="Sign In" />
        </div>
      </div>
    </Animate>
  );
}
