import { Animate } from "components/lib";
import InboxViewer from "./components/InboxViewer/InboxViewer";
import ChatViewer from "./components/chatViewer/chatViewer";
import Style from "./chat.tailwind";

const Chat = () => (
  <Animate type="pop">
    <div className={Style.wrapper}>
      <InboxViewer />
      <ChatViewer />
    </div>
  </Animate >
);

export default Chat;
