import { Icon } from "components/lib";

const NoLeads = () => {
  return (
    <div className="h-[37.5rem] flex justify-center items-center">
      <div className="flex flex-col items-center">
        <h1 className="text-slate-600 mb-4">No leads were created...</h1>
        <Icon image='user-x' size={80} className="text-red-500" />
      </div>
    </div>
  );
};

export default NoLeads;
