import { useState, Fragment } from "react";
import { Form } from "components/lib";
import Styles from "./verifyAccount.tailwind";

const VerifyAccount = (props) => {
  const [verify, setVerify] = useState(false);
  return (
    <>
      {verify ? (
        <div className={Styles.container}>
          <h1 className={Styles.title}>You are all set! 🎉🎉🎉</h1>
        </div>
      ) : (
        <Fragment>
          <p className={Styles.noteTitle}>NOTE*</p>
          <p className={Styles.note}>
            A verification has been sent to your email or phone. Please enter
            the code below to validate the account.
          </p>
          <Form
            data={{
              linkedinAccountId: {
                label: 'user id',
                type: 'hidden',
                value: localStorage.getItem('linkedinAccountId')
                  ? localStorage.getItem('linkedinAccountId')
                  : 'Value not found',
              },
              authenticationCode: {
                label: 'Code',
                type: 'text',
                required: true,
                placeholder: 'Enter code',
              },
            }}
            buttonText={props.buttonTextProp ? 'Verified' : 'Verify'}
            url="/api/submit-auth-code-linkedin"
            method="POST"
            callback={() => {
              setVerify(true);
              props.submitProp();
            }}
          />
        </Fragment>
      )}
    </>
  );
};

export default VerifyAccount;
