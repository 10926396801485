import { useState } from "react";
import { Icon, Animate } from "components/lib";
import { useEffect, useSelector } from "react-redux";
import { setSelectedMessage, setMessages } from "redux/features/linkedboostStore";
import nullImage from "../../images/null_pic.webp";
import MessageModal from "../messageModal/messageModal";

const Message = ({
  index,
  user,
  users,
  dispatch,
  id,
  updateFavorite,
  removeFavorite,
  addToArchive,
  removeArchive,
  readMessages,
  setReadMessages,
}) => {
  const [modal, setModal] = useState(false);
  const messages = useSelector((state) => state.linkedboostStore.messages);
  const selectedMessage = useSelector((state) => state.linkedboostStore.selectedMessage);
  const [favorite, setFavorite] = useState(false);
  const [archive, setArchive] = useState(false);

  const handleReadMessagesUpdate = (user) => {
    const readMessagesClone = [...readMessages];
    if (!readMessagesClone.includes(user.id)) {
      const state = [...readMessagesClone, user.id];
      setReadMessages(state);
    }
  };

  const handleArchiveUpdate = (id) => {
    const messagesClone = [...messages];
    const selectedMessage = messagesClone.find((item) => item.id === id);
    const filteredMessages = messagesClone.filter((item) => item.id !== id);
    const state = [...filteredMessages, { id: selectedMessage.id, favorite: false, archive: !selectedMessage.archive, messages: selectedMessage.messages }];
    state.sort((a, b) => a.id - b.id);
    dispatch(setMessages(state));
  };

  return (
    <div
      key={user.id}
      className={`realtive group px-3 flex items-center justify-between border-b border-slate-200 
      cursor-pointer py-3 hover:bg-slate-200 bg-white relative ${readMessages?.length ? readMessages.includes(user.id) ? "bg-red-600" : "bg-zinc-100" : "bg-slate-300"}`}
      onClick={() => {
        dispatch(setSelectedMessage(user));
        // handleReadMessagesUpdate(user);
      }}
    >
      {selectedMessage && selectedMessage.id === user.id && (
        <div className="bg-green-400 absolute w-[0.25em] h-full left-0 animate-pulse" />
      )}

      <img src={user.profile_picture ? user.profile_picture : nullImage} alt="profile" className="border border-slate-200 rounded-full antialiased h-14 w-14" />
      <div
        className="pl-2 w-5/6 flex flex-col"
      >
        <div className="flex justify-between">
          <h1 className="text-slate-800 text-lg m-0 p-0">{user.lead_name}</h1>
          <p className="text-xs flex group-hover:hidden">{user.messages[user.messages.length - 1].timestamp}</p>
          <div
            id={id}
            className="message-options-button absolute right-4"
            onClick={() => setModal(!modal)}
          >
            <Icon image="more-horizontal" size={25} color="dark" className="hidden group-hover:flex hover:bg-slate-300 rounded-full" />
          </div>
        </div>
        <p className="text-sm">{user.messages[user.messages.length - 1].content.slice(0, 29) + '...'}</p>
      </div>
      {modal &&
        <MessageModal
          setModal={setModal}
          messages={messages}
          user={user}
          removeFavorite={removeFavorite}
          handleArchiveUpdate={handleArchiveUpdate}
          updateFavorite={updateFavorite}
          addToArchive={addToArchive}
          removeArchive={removeArchive}
          archive={archive}
          setArchive={setArchive}
        />
      }
    </div>
  );
};

export default Message;
