const Style = {
  tabs: "overflow-hidden border-b border-solid border-red-500 md:border-0",
  button: `relative float-left w-full px-6 py-2 outline-none text-sm
    ease-in-out duration-300 border-b border-solid border-slate-100 last:border-0
    md:w-auto md:border-0 md:bg-blue-400`,

  buttonActive: "text-slate-600 md:bg-white md:border-b-2 md:border-blue-400",
  buttonInActive: "text-green-500 bg-white md:text-white md:bg-green-500",
};

export default Style;
