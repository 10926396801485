import axios from 'axios';
import Swal from 'sweetalert2';

const createCampaign = (followUpMessages, linkedins, viewContext, navigate) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const csvFile = JSON.parse(localStorage.getItem("file"));
  const form = JSON.parse(localStorage.getItem('campaignDetails'));
  const workingDays = JSON.parse(localStorage.getItem('WorkingDays'));
  const workingDaysIntergerValues = workingDays.map((item) => parseInt(item));
  const followUpMessagesInteger = followUpMessages.map((item) => {
    return {
      message: item.message,
      days: parseInt(item.number),
    };
  });
  form.follow_up_messages = followUpMessagesInteger;
  form.linkedinAccount_ids = linkedins;
  form.work_week_days = workingDaysIntergerValues;
  const URL = '/api/create-campaign';
  const config = {
    headers: {
      'Contenty-Type': 'application/json',
      Authorization: 'Bearer ' + user.token,
    },
  };

  axios.post(URL, form, config).then((res) => {
    const campaignId = res.data.data.id;
    const leadsURL = '/api/scrape-leads';
    const multidataConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + user.token,
      },
    };

    if (res.data.data.search_url.length === 0) {
      fetch(csvFile.url).then((res) => {
        res.blob().then((blob) => {
          const formData = new FormData();
          formData.append('campaign_id', campaignId);
          formData.append('file', blob, csvFile.name);
          axios.post(leadsURL, formData, multidataConfig)
            .then(() => {
              localStorage.setItem("file", "[]");
              window.location = '/campaigns';
            })
            .catch((error) => {
              viewContext.notification.show(error, 'error', true);
              localStorage.setItem("file", "[]");
              localStorage.setItem("updateFile", "false");
              window.location = '/campaigns';
            });
        });
      });
    } else {
      const formData = new FormData();
      formData.append('campaign_id', campaignId);
      formData.append('file', undefined);
      axios.post(leadsURL, formData, multidataConfig)
        .then(() => {
          localStorage.setItem("file", "[]");
          localStorage.setItem("updateFile", "false");
          navigate('/campaigns');
          Swal.fire({
            title: 'Campaign is being created!',
            text: 'Please check it out once is "running"',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        })
        .catch((err) => {
          viewContext.notification.show(err, 'error', true);
          Swal.fire({
            title: 'An error ocurred ❌',
            text: `${err.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'Cool',
          });
          localStorage.setItem("file", "[]");
          localStorage.setItem("updateFile", "false");
        });
    }

  }).catch((err) => {
    Swal.fire({
      title: 'An error ocurred ❌',
      text: `${err.response.data.message}`,
      icon: 'error',
      confirmButtonText: 'Cool',
    });
  });
  localStorage.setItem('AddCampaignTabs', '0');
};

const updateCampaign = (campaignId, followUpMessages, navigate) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const form = JSON.parse(localStorage.getItem('campaignDetails'));
  const workingDays = JSON.parse(localStorage.getItem('WorkingDays'));
  const workingDaysIntergerValues = workingDays.map((item) => parseInt(item));
  const followUpMessagesInteger = followUpMessages.map((item) => {
    return {
      ...item,
      number: parseInt(item.number),
    };
  });
  form.campain_id = campaignId;
  form.follow_up_messages = followUpMessagesInteger;
  form.work_week_days = workingDaysIntergerValues;
  form.status = "running";
  const URL = '/api/update-campaign';
  const config = {
    headers: {
      'Contenty-Type': 'application/json',
      Authorization: 'Bearer ' + user.token,
    },
  };
  axios.post(URL, form, config).then(() => {
    localStorage.setItem("file", "[]");
    localStorage.setItem("updateFile", "false");
    navigate('/campaigns');
    Swal.fire({
      title: 'Campaign has been updated!',
      icon: 'success',
      confirmButtonText: 'Ok',
    });
  })
    .catch(() => {
      navigate('/campaigns');
      Swal.fire({
        title: 'An error ocurred ❌',
        icon: 'error',
        confirmButtonText: 'Cool',
      });
      localStorage.setItem("file", "[]");
      localStorage.setItem("updateFile", "false");
    });
  localStorage.setItem('AddCampaignTabs', '0');
  localStorage.setItem('CampaignDetails', '[]');
};

export { createCampaign };
export { updateCampaign };
