import React, { useState, useEffect } from "react";
import { Card, Animate, Feedback, useAPI, TabViewMain } from "components/lib";
import { setCampaigns, setAccounts, setSalesNavAccounts, setStats } from "redux/features/linkedboostStore";
import { useDispatch } from "react-redux";
import FilterOverall from "./components/filterOverall/filterOverall";
import SentConnectionsGraph from "./components/sentConnections/sentConnections";
import ReplyRate from "./components/replyRate/replyRate";
import DashboardMetrics from "./components/dashboardMetrics/dashboardMetrics";
import Styles from "./dashboard.tailwind";

export function Dashboard() {
  const [storedStats, setStoredStats] = useState({});
  const stats = useAPI("/api/list-leads-metrics");
  const campaigns = useAPI("/api/list-all-campaigns");
  const linkedinAccounts = useAPI("/api/list-all-linkedin-accounts");
  const dispatch = useDispatch();

  if (window.location.reload) {
    localStorage.removeItem("ActivityTabs");
    localStorage.removeItem("CustomTabs");
  }

  useEffect(() => {
    if (campaigns.data?.length) dispatch(setCampaigns(campaigns.data));
  }, [campaigns.data, dispatch]);

  useEffect(() => {
    if (stats.data) {
      dispatch(setStats(stats.data));
      setStoredStats(stats.data);
    }
  }, [stats.data]);

  useEffect(() => {
    if (linkedinAccounts.data?.length) {
      const allAccounts = [];
      linkedinAccounts.data.map((item) => {
        allAccounts.push({
          value: item.id,
          label: item.email,
        });
      });
      const salesNavAccounts = [];
      for (let i = 0; i < linkedinAccounts.data?.length; i++) {
        if (linkedinAccounts.data[i].salesNavigator === true) salesNavAccounts.push(linkedinAccounts.data[i]);
      }
      localStorage.setItem("salesAccounts", JSON.stringify(salesNavAccounts));
      localStorage.setItem("linkedins", JSON.stringify(allAccounts));
      dispatch(setAccounts(linkedinAccounts.data));
      dispatch(setSalesNavAccounts(salesNavAccounts));
    }

    localStorage.setItem("dateRange", JSON.stringify({ startDate: null, endDate: null }));
  }, [linkedinAccounts.data]);

  return (
    <Animate type="pop">
      <Card title="Activity" className={Styles.container}>
        <TabViewMain name="Activity" labels={["Overall Insights", "Sent Connections", "Reply Rate"]}>
          <Card><FilterOverall /></Card>
          <Card><SentConnectionsGraph /></Card>
          <Card><ReplyRate /></Card>
        </TabViewMain>
      </Card>
      <DashboardMetrics stats={stats} storedStats={storedStats} />
      <Feedback />
    </Animate>
  );
}
