import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Form, ViewContext, useLocation, useNavigate } from "components/lib";
import { TailSpin, ThreeDots } from 'react-loader-spinner';
import Styles from './addAccount.tailwind';

const AddAccount = ({ setSubmit, buttonTextProp }) => {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const state = useLocation();
  useEffect(() => {
    if (loading) console.log(loading);
  }, [loading]);

  const msg1 = (
    <h1 className={Styles.note}>
      We will attempt to log in to Linkedin with the credetentials you have
      provided. You may be asked to enter a verification code that will be sent to
      the email address of the account owner. Please be sure you have access to
      this mailbox before proceeding.
    </h1>
  );

  return (
    <div>
      {loading && (
        <div className={Styles.loaderContainer}>
          <TailSpin
            height="150"
            width="150"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <h1 className={Styles.subtitle}>
            Please wait, we are validating your credentials 🪪
            <ThreeDots
              height="20"
              width="20"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </h1>
        </div>
      )}

      {!loading && !pending && (
        <>
          <p className={Styles.noteTitle}>NOTE*</p>
          {msg1}
          <Form
            data={{
              email: {
                label: 'Email',
                type: 'email',
                required: true,
                placeholder: 'Your linkedin account email address here...',
                value: state.state ? state.state : '',
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
              },
            }}
            buttonText={
              buttonTextProp
                ? 'Account Created ✅'
                : 'Add Linkedin account'
            }
            url="/api/create-linkedin-account"
            method="POST"
            callback={(res) => {
              const user = JSON.parse(localStorage.getItem('user'));
              setLoading(true);
              const url = '/api/login-linkedin-account';
              const config = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${user.token}`,
                },
              };
              const data = { linkedinAccountId: res.data.data.id };
              localStorage.setItem('linkedinAccountId', res.data.data.id);
              axios
                .post(url, data, config)
                .then((res) => {
                  if (res.data.data.status === '2_factor_pending') {
                    setSubmit(1);
                    setLoading(false);
                    setPending(true);
                  } else if (res.data.data.status === 'invalid_credentials') {
                    Swal.fire({
                      title: 'Invalid credentials 😔',
                      text: 'Please check the mail or password provided, we were not able to verify your information👮',
                      icon: 'error',
                      confirmButtonText: 'Cool',
                    });
                    navigate('/linkedins');
                  } else if (res.data.data.status === 'active') {
                    Swal.fire({
                      title: 'Account added successfully! 🎉',
                      text: 'You can now start using Linkedboost',
                      icon: 'success',
                      confirmButtonText: 'Cool',
                    });
                    navigate('/linkedins');
                  } else if (res.data.data.status === 'blocked') {
                    Swal.fire({
                      title: 'Account blocked ❌',
                      text: 'This account is blocked, please try with a new one',
                      icon: 'error',
                      confirmButtonText: 'Cool',
                    });
                    navigate('/linkedins');
                  } else if (res.data.data.status === "new") {
                    alert("new status TEST");
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    title: 'Error!',
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Cool',
                  });
                  navigate('/linkedins');
                });
            }}
          />
        </>
      )}

      {!loading && pending && (
        <div className={Styles.verification}>
          <h1 className={Styles.verificationTitle}>
            Almost there, please verify the account 🔑
          </h1>
        </div>
      )}
    </div>
  );
};

export default AddAccount;
