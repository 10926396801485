/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Message } from 'components/lib';

export function OnboardingView() {
  const context = useContext(AuthContext);
  const views = [
    {
      name: 'Getting Started',
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome />,
    },
  ];

  if (context.permission.admin) {
    views.push({
      name: 'Invite your team',
      description: 'Invite your team to LinkedBoost',
      component: <InviteUsers />,
    });
  }

  if (context.user.duplicate_user) {
    views.unshift({
      name: 'Important!',
      description: '',
      component: <DuplicateUser />,
    });
  }

  return <Onboarding save onFinish="/dashboard" views={views} />;
}

function DuplicateUser() {
  return (
    <Message
      type="warning"
      title={`You already have an account`}
      text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
    />
  );
}

function Welcome() {
  return (
    <Fragment>
      <p>
        Hello and welcome to LinkedBoost! We're thrilled to have you join our
        platform and unleash the full potential of your LinkedIn network.
      </p>

      <p>
        <strong>Let's start automating your LinkedIn outreach!</strong>
      </p>
    </Fragment>
  );
}

function InviteUsers() {
  return (
    <Form
      data={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        },
      }}
      buttonText="Send Invite"
      url="/api/invite"
      method="POST"
    />
  );
}
