import { useState, useEffect } from "react";
import { useAPI } from "components/lib";
import userImage from "./userImage.jpg";
import nullImage from "../../images/null_pic.webp";
import Styles from "./chatWindow.tailwind";

const ChatWindow = ({ fetchMessage }) => {
  const [campaignName, setCampaignName] = useState("");
  const list = useAPI("/api/list-all-campaigns");

  useEffect(() => {
    if (list?.data?.length && fetchMessage) {
      const campaign = list.data.filter((item) => item.id === fetchMessage.campaign_id);
      setCampaignName(campaign[0].name);
    }
  }, [list.data, fetchMessage]);

  return (
    <>
      {fetchMessage !== undefined && (
        <div className={Styles.container}>
          <div>
            <h1 className={Styles.title}>
              <span className="font-bold">
                {campaignName}:
              </span>
              {fetchMessage.lead_name}
            </h1>
          </div>
          <div className={Styles.messagesContainer}>
            {fetchMessage.messages && fetchMessage.messages.map((message, index) => (
              <div key={index + 1} className={Styles.message}>
                <div className={Styles.innerMessage}>
                  <div className={Styles.imageContainer}>
                    {message.author === fetchMessage.lead_name ? (
                      <img src={nullImage} alt="profile" className={Styles.image} />
                    ) : (
                      <img src={userImage} alt="profile" className={Styles.image} />
                    )}
                  </div>
                  <div className={Styles.textWrapper}>
                    <div className={Styles.header}>
                      <h1 className={Styles.user}>{message.author === fetchMessage.lead_name ? fetchMessage.lead_name : "You"}</h1>
                      <p className="text-sm">{message.time}</p>
                    </div>
                    <p className={Styles.card}>{message.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {fetchMessage === undefined && (
        <div className={Styles.nomsgContainer}>
          <div className={Styles.nomsgTitleContainer}>
            <h1 className={Styles.nomsgTitle}><span> Campaign : </span></h1>
          </div>
          <div className={Styles.nomsgWrapper}>
            <h1 className={Styles.nomsg}>
              No messages 📥
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatWindow;
