import { Fragment, useEffect } from 'react';
import { setAccounts } from 'redux/features/linkedboostStore';
import { useDispatch, useSelector } from 'react-redux';
import { useAPI, Animate, useNavigate } from 'components/lib';
import Header from './components/header/Header';
import LinkedInsTable from 'views/linkedins/components/linkedinsTable/LinkedinsTable';

export function Linkedins() {
  const list = useAPI('/api/list-all-linkedin-accounts');
  const dispatch = useDispatch();
  const accountsState = useSelector((state) => state.linkedboostStore.linkedinAccounts);
  const navigate = useNavigate();

  useEffect(() => {
    if (list?.data?.length) dispatch(setAccounts(list?.data));
  }, [list, dispatch]);

  return (
    <Fragment>
      <Animate type="pop">
        <Header
          title='Linkedin Accounts'
          buttonText="+ Add Linkedin Account"
          action={() => navigate('/addlinkedin')}
        />
        <LinkedInsTable linkedins={accountsState} list={list} />
      </Animate>
    </Fragment>
  );
}
