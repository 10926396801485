const Style = {

  plans: 'block, mb-4 w-full gap-3 mt-4 flex lg:px-10 xl:flex-row flex-col justify-center items-center lg:items-start',
  plan: 'border border-slate-100 rounded-lg shadow-lg lg:w-full w-5/6 h-1/2',
  name: 'absolute font-semibold text-center font-sm text-brand-500 uppercase md:relative md:text-slate-500',
  price: 'absolute text-slate-700 font-bold text-lg right-4 top-5 md:relative md:text-3xl md:top-auto md:right-auto md:mt-4',
  features: 'columns-2 ml-0 mt-10 mb-5 md:columns-auto md:mt-4',

};

export default Style;
