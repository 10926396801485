import { Icon } from "components/lib";
import nullImage from "../../images/null_pic.webp";

const Details = ({ fetchMessage }) => (
  <>
    {fetchMessage !== undefined && fetchMessage.messages && (
      <div className="w-[35%] hidden xl:flex xl:flex-col overflow-hidden">
        <h1 className="text-lg text-slate-700 font-semibold h-[5%] tracking-wider">Details</h1>
        <div className="flex flex-col items-center p-4 h-78 border-b border-slate-200 shadow-sm">
          <div className="h-64 flex items-center overflow-hidden rounded-lg w-[21em] relative object-fill shadow-xl">
            <img src={fetchMessage.lead.profile_picture ? fetchMessage.profile_picture : nullImage} className="object-cover" />
          </div>
          <h1 className="text-xl font-bold text-slate-800 mt-2">{fetchMessage.lead_name}</h1>
          <p className="font text-center">{fetchMessage.lead.sub_title}</p>
        </div>
        <div className="py-10 pl-4 h-60 flex flex-col items-start justify-between">
          <div className="flex items-center justify-start">
            <Icon image="briefcase" size={20} className="mr-2" />
            <h1 className="text-left text-base text-slate-700">
              {fetchMessage.lead.company_name}
            </h1>
          </div>
          <div className="flex items-center justify-start">
            <Icon image="linkedin" size={20} className="mr-2 mb-1" />
            <a href="https://linkedin.com">
              <h1 className="text-left text-base text-blue-600">
                {fetchMessage.lead.linkedin_id}
              </h1>
            </a>
          </div>
          <div className="flex items-center justify-start">
            <Icon image="mail" size={20} className="mr-2" />
            <h1 className="text-left text-base text-slate-700">
              email here
            </h1>
          </div>
          <div className="flex items-center justify-start">
            <Icon image="phone" size={20} className="mr-2" />
            <h1 className="text-left text-base text-slate-700">
              phone here
            </h1>
          </div>
          <div className="flex items-center justify-start">
            <Icon image="globe" size={20} className="mr-2" />
            <h1 className="text-left text-base text-slate-700">
              {fetchMessage.lead.location}
            </h1>
          </div>
        </div>
      </div>
    )}

    {fetchMessage === undefined && (
      <div className="w-[35%] hidden xl:flex xl:flex-col">
        <h1 className="text-lg text-slate-700 font-semibold h-[5%] tracking-wider">
          Details
        </h1>
        <div className="flex flex-col items-center px-4 pb-4 h-78 border-b border-slate-200 shadow-sm">
          <div className="h-64 flex items-center overflow-hidden rounded-lg w-[21em] relative object-fill shadow-xl">
            <img src={nullImage} className="object-cover" />
          </div>
        </div>
      </div>
    )}
  </>
);

export default Details;
