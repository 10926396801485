import { useContext, useEffect, useState } from "react";
import { ViewContext, Card, Table, useAPI, useLocation, useNavigate, Grid, Stat } from "components/lib";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedLead, setSelectedLeads, setSelectedMessage } from "redux/features/linkedboostStore";
import Pagination from "./components/pagination";
import LeadsLoader from "./leadsLoader";
import NoLeads from "./noLeads";

export function Leads() {
  const navigate = useNavigate();
  const [selectedCampaign, setSelectedCampaign] = useState(undefined);
  const state = useLocation();
  const leads = useSelector((state) => state.linkedboostStore.selectedLeads);
  const [usePagination, setUsePagination] = useState(0);
  const [repliedLeads, setRepliedLeads] = useState(0);
  const [pendingLeads, setPendingLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [linkedins, setLinkedins] = useState([]);
  const [leadsPerPage] = useState(5);
  const campaignList = useAPI("/api/list-all-campaigns");
  const linkedinList = useAPI("/api/list-all-linkedin-accounts");
  const context = useContext(ViewContext);
  const list = useAPI(`/api/list-all-leads-from-campaign/${state.state}`);
  const dispatch = useDispatch();

  useEffect(() => {
    if (list.data?.length) {
      dispatch(setSelectedLeads(list.data));
      const repliedLeads = list.data.filter((item) => item.status === "replied");
      const pendingLeads = list.data.filter((item) => item.status === "connection_not_requested");
      setRepliedLeads(repliedLeads.length);
      setPendingLeads(pendingLeads.length);
    }
  }, [list.data]);

  useEffect(() => {
    if (campaignList.data?.length) {
      const auxFilter = campaignList.data.filter((item) => item.id === state.state);
      setSelectedCampaign(auxFilter);
    }
  }, [campaignList.data]);

  useEffect(() => {
    if (linkedinList.data?.length) {
      setLinkedins(linkedinList.data);
    }
  }, [linkedinList.data]);

  const deleteLead = () => {
    context.modal.show({
      title: "Delete Linkedin Account",
      form: {},
      buttonText: "Yes, delete",
      text: `Are you sure you want to delete this Campaign?`,
      method: "DELETE",
    });
  };

  const navigateLead = (data) => {
    const clone = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => key !== "actions"),
    );
    dispatch(setSelectedLead(clone));
    context.modal.showLead({
      title: "Lead Connection",
      first_name: clone.first_name,
      last_name: clone.last_name,
      company_name: clone.company_name,
      info: clone.sub_title,
      status: clone.status,
      id: clone.id,
      form: {},
    });
  };

  const navigateChat = (data) => {
    dispatch(setSelectedMessage(data));
    navigate("/inbox");
  };

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);

  const paginate = (page) => {
    setUsePagination(usePagination + 1);
    setCurrentPage(page);
  };

  const navigateToParentLinkedin = (res) => {
    const leadOwner = linkedins.filter((item) => item.id === res.linkedinAccount_id);
    context.modal.showParent({
      title: "Parent Linkedin Account",
      first_name: leadOwner[0].profile_info.first_name,
      last_name: leadOwner[0].profile_info.last_name,
      company_name: leadOwner[0].profile_info.company_name,
      info: leadOwner[0].profile_info.sub_title,
      status: leadOwner[0].status,
      profile_picture: leadOwner[0].profile_info.profile_picture,
      profile_id: leadOwner[0].profile_id,
      form: {},
    });
  };

  return (
    <>
      {list.data?.length > 0 && (
        <div className="xl:mx-10">
          <Grid cols="4">
            <Stat loading={list.data?.loading} value={list.data?.length} label="Leads Created" icon="linkedin" />
            <Stat loading={list.data?.loading}
              value={list.data?.length ? repliedLeads : list.data?.length}
              label="Replied Connections"
              icon="user-check"
            />
            <Stat
              loading={list.data?.loading}
              value={list.data?.length ? pendingLeads : list.data?.length}
              label="To Be Sent Connections"
              icon="clock"
            />
            <Stat
              loading={list.data?.length === 0 && true}
              value={list.data?.length && `${Math.abs(Math.round(
                list.data?.length
                  ? (((pendingLeads / leads.length) * 100) - 100).toFixed(2)
                  : 0,
              ))} %`}
              label="Success Ratio"
              icon="activity"
            />
          </Grid>
        </div>
      )}

      <Card className="xl:mx-10 shadow-md">
        {selectedCampaign && list.data?.length > 0 && selectedCampaign[0].status === "running" && (
          <Table
            className="restrict-width"
            data={currentLeads}
            loading={leads.loading}
            show={[
              "first_name",
              "last_name",
              "company_name",
              "location",
              "status",
            ]}
            sort
            actions={{
              delete: deleteLead,
              custom: [
                { icon: "eye", action: navigateLead },
                { icon: "link-2", action: navigateToParentLinkedin },
                {
                  icon: "message-circle",
                  action: navigateChat,
                  condition: {
                    col: 'status',
                    value: 'replied',
                  },
                },
              ],
            }}
            badge={{
              col: "status",
              color: "blue",
              condition: [
                { value: "connection_not_requested", color: "orange" },
                { value: "connection_accepted", color: "green" },
                { value: "connection_rejected", color: "red" },
              ],
            }}
          />
        )}

        {selectedCampaign === undefined && (
          <LeadsLoader title={"Loading leads..."} />
        )}

        {list.data?.length === 0 && <NoLeads />}

      </Card >
      {list.data?.length > 0 && (
        <Card className="xl:mx-10 shadow-md">
          <Pagination
            leadsPerPage={leadsPerPage}
            totalLeads={leads.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Card>
      )}
    </>
  );
}
