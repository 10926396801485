const badges = {
  col: "status",
  color: "blue",
  condition: [
    { value: "running", color: "green" },
    { value: "paused", color: "orange" },
    { value: "finished", color: "blue" },
  ],
};

export { badges };
