import { updateLinkedinAccount } from '../components/api/updateLinkedinAccount';
import { deleteAccount } from 'redux/features/linkedboostStore';

const verifyAccount = (e, navigate, context) => {
  if (e.status === '2_factor_pending' || e.status === 'new') {
    navigate('/revalidate', {
      state: { id: e.id },
    });
  } else if (e.status === 'invalid_credentials') {
    context.modal.show({
      title:
        'Invalid credentials. Please update your account ⚠️',
    });
  } else {
    context.modal.show({
      title: 'Your Account is already active! 👌',
    });
  }
};

const navigateLinkedinAccount = (
  e,
  linkedins,
  setSelectedAccount,
  modal,
  setModal,
) => {
  const aux = linkedins.filter((account) => account.id === e.id);
  setSelectedAccount(aux);
  setModal(!modal);
};

const editLinkedin = (e, setLoading, context) => {
  context.modal.showUpdate({
    title: 'Update Linkedin Account Details',
    form: {
      linkedinAccount_id: {
        type: 'hidden',
        value: e.id,
      },
      email: {
        type: 'text',
        placeholder: 'Enter email',
        required: true,
        value: e.email,
        errorMessage: 'Please enter an email',
      },
      password: {
        type: 'text',
        placeholder: 'Enter new password',
        required: true,
        errorMessage: 'Please enter a password',
      },
    },
    url: '/api/update-linkedin-email-and-password',
    method: 'PATCH',
    buttonText: 'Update',
    text: 'We will validate the account to ensure you entered the information correctly',
  },
    (res) => updateLinkedinAccount(res, setLoading, e, context));
};

const deleteLinkedinAccount = (e, context, dispatch) => {
  context.modal.show(
    {
      title: 'Delete Linkedin Account',
      form: {},
      buttonText: 'Yes, delete',
      text: `Are you sure you want to delete ${e.email} account?`,
      url: `/api/delete-linkedin-account/${e.id}`,
      method: 'DELETE',
    },
    () => {
      context.notification.show('Deleted succesfully', 'success', true);
      dispatch(deleteAccount(e.id));
    },
  );
};

const badges = {
  col: 'status',
  condition: [
    { value: 'active', color: 'green' },
    { value: '2_factor_pending', color: 'orange' },
    { value: 'blocked', color: 'red' },
    { value: 'invalid_credentials', color: 'red' },
    { value: 'new', color: 'blue' },
  ],
};

const navigateAction = (linkedins, setSelectedAccount, modal, setModal) => {
  return (
    {
      icon: 'eye',
      action: (e) => navigateLinkedinAccount(e, linkedins, setSelectedAccount, modal, setModal),
      condition: {
        col: 'status',
        value: "active",
      },
    }
  );
};

const editAction = (setLoading, context) => {
  return (
    {
      icon: 'edit',
      action: (e) => editLinkedin(e, setLoading, context),
      condition: {
        col: 'status',
        value: "invalid_credentials",
      },
    }
  );
};

const invalidCredentialsAction = (navigate, context) => {
  return (
    {
      icon: 'help-circle',
      action: (e) => verifyAccount(e, navigate, context),
      condition: {
        col: 'status',
        value: "invalid_credentials",
      },
    }
  );
};

const verify2faAction = (navigate, context) => {
  return (
    {
      icon: 'key',
      action: (e) => verifyAccount(e, navigate, context),
      condition: {
        col: 'status',
        value: '2_factor_pending',
      },
    }
  );
};

const verifyNewAction = (navigate, context) => {
  return (
    {
      icon: 'key',
      action: (e) => verifyAccount(e, navigate, context),
      condition: {
        col: 'status',
        value: 'new',
      },
    }
  );
};

const verifiedAccountMessageAction = (navigate, context) => {
  return (
    {
      icon: 'check',
      action: (e) => verifyAccount(e, navigate, context),
      condition: {
        col: 'status',
        value: 'active',
      },
    }
  );
};

export {
  badges,
  navigateAction,
  editAction,
  invalidCredentialsAction,
  verify2faAction,
  verifyNewAction,
  verifiedAccountMessageAction,
  verifyAccount,
  navigateLinkedinAccount,
  editLinkedin,
  deleteLinkedinAccount,
};
