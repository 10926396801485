import { useContext } from "react";
import { ViewContext, Form, Icon } from "components/lib";
import { CSSTransition } from "react-transition-group";
import nullPic from "../../views/linkedins/null_pic.webp";
import "./modalShowAccount.scss";

export function ModalShowAccount(props) {
  const context = useContext(ViewContext);
  const name = props[0].name.split(" ");

  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) =>
          e.target === e.currentTarget && context.modal.hide(true)
        }
      >
        <div className="modal-content bg-white">
          <div>
            <div className="flex justify-end">
              <div
                onClick={() => props.setModal(false)}
                className="border border-slate-100 shadow-md p-2 right-2 top-2 rounded-full absolute cursor-pointer hover:bg-slate-800 hover:text-white"
              >
                <Icon image="x" size={25} />
              </div>
            </div>
            <img
              className="h-[300px] w-full object-cover"
              src={props[0].profile_info.profile_picture ? props[0].profile_info.profile_picture : nullPic}
            />
            <h1 className="text-left ml-8 mt-6 text-2xl text-slate-700 font-bold italic">
              {name[0] ? name[0].slice(0, 1).toUpperCase() + name[0].slice(1) + " " + name[1].slice(0, 1).toUpperCase() + name[1].slice(1) : "Not available"}
            </h1>
            <div className="flex items-center justify-start mt-4 ml-8">
              <Icon image="home" size={20} className="mr-2" />
              <h1 className="text-left text-xl text-slate-700">
                {props.company ? props.company : "Not Available"}
              </h1>
            </div>
            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="briefcase" size={20} className="mr-2" alt="LOL" />
              <h1 className="text-left text-xl text-slate-700">
                {props[0].profile_info.sub_title ? props[0].profile_info.sub_title : "Not available"}
              </h1>
            </div>
            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="mail" size={20} className="mr-2" />
              <h1 className="text-left text-xl text-slate-700">
                {props[0].email ? props[0].email : "No registered email"}
              </h1>
            </div>
            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="globe" size={20} className="mr-2" />
              <h1 className="text-left text-xl text-slate-700">
                {props[0].profile_info.location}
              </h1>
            </div>
            <div className="flex items-center justify-start mt-1 ml-8">
              <Icon image="activity" size={20} className="mr-2" />
              <h1 className="text-left text-xl text-slate-700">
                {props[0].status ? (props[0].status[0].toUpperCase() + props[0].status.slice(1)) : "Not available"}
              </h1>
            </div>
            <div className="p-2 flex justify-end"></div>
            {props.form && (
              <Form
                method={props.method}
                url={props.url}
                data={props.form}
                destructive={props.destructive}
                buttonText={props.buttonText}
              />
            )}

            {props.text && (
              <div>
                <p className="mt-4 font-bold text-red-600 text-sm">*Note</p>
                <p className="text-left text-sm">{props.text}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
