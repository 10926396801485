const handleSearchMain = (e, users, setFilteredUsers) => {
  const filteredUsers = users.filter((user) => (
    (user.lead_name.toLocaleLowerCase()).includes(e.target.value.toLowerCase())
  ));
  if (e.target.value === "") {
    setFilteredUsers([]);
  } else {
    setFilteredUsers(filteredUsers);
  }
};

const handleSearchUnread = (e, unreadMessages, setFilteredUsers) => {
  const value = e.target.value.toLowerCase();
  const filteredUsers = unreadMessages.filter((user) => (user.name.toLocaleLowerCase()).includes(value));
  setFilteredUsers(filteredUsers);
};

const handleSearchFavorites = (e, starredUsers, setFilteredFavoriteUsers) => {
  const value = e.target.value.toLowerCase();
  const filteredUsers = starredUsers.filter((user) => (user.name.toLocaleLowerCase()).includes(value));
  setFilteredFavoriteUsers(filteredUsers);
};

const handleSearchArchives = (e, archiveMessages, setFilteredArchiveUsers) => {
  const value = e.target.value.toLowerCase();
  const filteredUsers = archiveMessages.filter((user) => (user.name.toLocaleLowerCase()).includes(value));
  setFilteredArchiveUsers(filteredUsers);
};

const showSearchModal = (setSearchModal, searchModal) => {
  setSearchModal(!searchModal);
};

export {
  handleSearchMain,
  handleSearchUnread,
  handleSearchFavorites,
  handleSearchArchives,
  showSearchModal,
};
