import { Animate, useNavigate } from "components/lib";
import Header from "../linkedins/components/header/Header";
import CampaignsTable from "./components/CampaignsTable";

export function Campaigns() {
  const navigate = useNavigate();
  const openCreateCampaignForm = () => navigate("./createCampaign");

  return (
    <Animate type="pop">
      <Header
        title="Campaigns"
        buttonText="+ Add New Campaign"
        action={openCreateCampaignForm}
      />
      <CampaignsTable />
    </Animate>
  );
}
