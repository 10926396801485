const Styles = {
  note: "text-sm md:text-base mb-8",
  noteTitle: "text-sm font-bold text-red-600 mb-0",
  loaderContainer: "w-full flex flex-col justify-center items-center pt-12",
  subtitle: "mt-12 text-slate-500 text-lg flex items-end",
  verification: "flex justify-center p-5 h-52 items-center",
  verificationTitle: "text-xl text-slate-500",
};

export default Styles;
