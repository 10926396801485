import { Fragment, useContext } from 'react';
import { AuthContext, Link, Button } from 'components/lib';

export function HomeNav() {
  const context = useContext(AuthContext);

  return (
    <div className="flex justify-between items-center lg:px-24 xl:px-40 px-4 bg-white h-20 z-10 shadow-lg lg:py-10">
      <Link
        url="/"
        className="mr-3 lg:mr-6 no-underline hover:text-green-500 lg:text-lg hover:scale-105 ease-in-out duration-100"
        color="black"
      >
        <h1 className="cursor-pointer lg:text-4xl xl:text-4xl text-2xl font-bold">
          Linkedboost<span className="text-2xl">&#174;</span>
        </h1>
      </Link>
      <nav className="w-1/2 flex justify-end items-center">
        <Link
          url="/pricing"
          text="Pricing"
          className="mr-3 lg:mr-6 no-underline hover:text-green-600 lg:text-lg hidden md:inline-block hover:scale-105 ease-in-out duration-100"
          color="black"
        />

        {context.user?.token ? (
          <Button
            small
            goto="/dashboard"
            text="Dashboard"
            className="hover:scale-105 ease-in-out duration-100"
          />
        ) : (
          <Fragment>
            <Link
              url="/signin"
              text="Sign In"
              className="mr-3 lg:mr-6 no-underline hover:text-green-600 lg:text-lg hidden md:inline-block hover:scale-105 ease-in-out duration-100"
              color="black"
            />
            <Button
              small
              goto="/signup"
              text="Sign Up"
              className="inline-block lg:w-36 w-28 md:inline-block hover:scale-105 ease-in-out duration-100"
            />
          </Fragment>
        )}
      </nav>
    </div>
  );
}
