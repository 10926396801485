const Style = {
  form: "flex w-full border border-slate-300 rounded-lg overflow-hidden shadow-sm",
  textarea: "w-full p-2 flex-1 rounded-tl-lg rounded-bl-lg",
  container: "flex-2 border-l border-slate-300",
  iconsContainer: "flex p-2 justify-center items-center gap-1",
  sendButton: "w-full h-20",
  icon: "cursor-pointer rounded-full hover:bg-slate-200",
  blocker: "bg-slate-200 opacity-30 fixed top-0 left-0 right-0 bottom-0",
  emojisContainer: "absolute bottom-16 left-32",
};

export default Style;
