import { Label, Error, ClassHelper } from "components/lib";
import Style from "./selectCustom.tailwind";

export function SelectCustom(props) {
  let options = props.options;
  const error = props.errorMessage || "Please select an option";

  function change(e) {
    let value = e ? e.target?.value : "unselected";
    let valid = undefined;

    // validate
    valid = props.required && value === "unselected" ? false : true;
    props.onChange(props.name, value, valid);
    props.callback && props.callback(value);
  }

  const wrapperStyle = ClassHelper(Style, {
    className: props.className,
    success: props.valid === true,
    errorWrapper: props.valid === false,
    warningWrapper: props.warning,
  });

  const selectStyle = ClassHelper(Style, {
    select: true,
    error: props.valid === false,
    warning: props.warning,
  });

  return (
    <div className={Style.input + " " + props.containerClass}>
      <Label
        text={props.label}
        required={props.required}
        htmlFor={props.name}
      />

      <div className={wrapperStyle}>
        <div
          className="flex justify-between items-center"
          defaultValue={props.default}
          onClick={(e) => change(e)}
          id={props.name}
        >
          {options?.map((option, i) => {
            return (
              <option
                key={option.value}
                value={option.value}
                className={
                  props.daysOfTheWeekSelected?.includes(i.toString())
                    ? "bg-blue-500 px-4 py-2 rounded text-white flex flex-1 justify-center items-center border border-slate-300 cursor-pointer"
                    : "bg-slate-200 px-4 py-2 rounded flex flex-1 justify-center items-center cursor-pointer"
                }
                onClick={(e) => {
                  const state = props.daysOfTheWeekSelected;
                  if (!state.includes(e.target.value)) {
                    state.push(e.target.value);
                    e.target.className =
                      "bg-blue-500 px-4 py-2 rounded text-white";
                  } else {
                    state.splice(state.indexOf(e.target.value), 1);
                    e.target.className = "bg-slate-200 px-4 py-2 rounded";
                  }
                  state.sort();
                  props.setDaysOfTheWeekSelected(state);
                }}
              >
                {option.label}
              </option>
            );
          })}
        </div>
        {props.valid === false && (
          <Error message={error} className={Style.message} />
        )}
      </div>
    </div>
  );
}
