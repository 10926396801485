import React, { useContext } from 'react';
import { AuthContext, Onboarding } from 'components/lib';
import VerifyAccountRevalidate from "./components/verifyAccountRevalidate/VerifyAccountRevalidate";

export function RevalidateAccount() {
  const context = useContext(AuthContext);
  let views = [];

  if (context.permission.admin) {
    views.push({
      name: 'Verify your account',
      description: 'Scalate your business reach with Linkedboost and invite your team.',
      component: (<VerifyAccountRevalidate />),
    });
  }
  return <Onboarding save onFinish="/linkedins" views={views} />;
}
