import "react-app-polyfill/ie9";
import { createRoot } from "react-dom/client";
import App from "./app/app";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

const welcome = () => console.log("Welcome to Linkedboost 🚀");
welcome("de529c70-eb80-4dfb-9540-5075db7545bf");
