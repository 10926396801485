import {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { useAPI, Badge, Icon, useNavigate, ViewContext } from "components/lib";
import Style from "../../components/table/table.tailwind";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [people, setPeople] = useState([]);
  const context = useContext(ViewContext);
  const list = useAPI("/api/list-all-linkedin-accounts");
  useEffect(() => {
    if (list?.data?.length) setPeople(list.data);
  }, [list]);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  useLayoutEffect(() => {
    const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(selectedPeople.length === people.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople]);
  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : people);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }
  const navigate = useNavigate();
  const navigateCreate = () => {
    navigate("/addlinkedin");
  };
  const deleteLinkedinAccount = (data, callback) => {
    context.modal.show(
      {
        title: "Delete Linkedin Account",
        form: {},
        buttonText: "Yes, delete",
        text: `Are you sure you want to delete ${data.email} account?`,
        url: `/api/delete-linkedin-account/${data.id}`,
        method: "DELETE",
      },
      () => {
        context.notification.show("Deleted succesfully", "success", true);
        const state = [...people];
        const index = state.findIndex((item) => item.id === data.id);
        state.splice(index, 1);
        setPeople(state);
        callback();
      },
    );
  };
  return (
    <div className="px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={navigateCreate}
          >
            Add user
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative">
              {selectedPeople.length > 0 && (
                <div className="absolute top-0 left-16 flex h-12 items-center space-x-3 bg-white sm:left-12">
                  <button
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Delete Selected
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="relative w-16 px-8 sm:w-12 sm:px-6"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-6 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-4"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date Created
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-6 sm:pr-3"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {people.map((person) => (
                    <tr
                      key={person.email}
                      className={
                        selectedPeople.includes(person)
                          ? "bg-gray-50"
                          : undefined
                      }
                    >
                      <td className="relative w-16 px-8 sm:w-12 sm:px-6">
                        {selectedPeople.includes(person) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-6 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-4"
                          value={person.email}
                          checked={selectedPeople.includes(person)}
                          onChange={(e) => {
                            setSelectedPeople(
                              e.target.checked
                                ? [...selectedPeople, person]
                                : selectedPeople.filter((p) => p !== person),
                            );
                          }}
                        />
                      </td>
                      <td
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                          selectedPeople.includes(person)
                            ? "text-indigo-600"
                            : "text-gray-900",
                        )}
                      >
                        {person.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Badge
                          text={person.status}
                          color={
                            person.status === "active" ? "green" : "orange"
                          }
                          className={Style.badge}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {/* {person.date_created.replace("T", " ").split(".")[0]} */}
                        <p>test</p>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex">
                        <Icon
                          image="eye"
                          size={20}
                          color={"dark"}
                          className="mr-2"
                        />
                        {person.status === "active" && (
                          <Icon
                            image="check"
                            size={20}
                            color={"green"}
                            className="mr-2"
                          />
                        )}
                        {person.status === "2_factor_pending" && (
                          <Icon
                            image="key"
                            size={20}
                            color={"dark"}
                            className="mr-2"
                          />
                        )}
                        {selectedPeople.includes(person) && (
                          <div
                            onClick={deleteLinkedinAccount}
                            className="cursor-pointer"
                          >
                            <Icon image="trash-2" size={20} color={"red"} />
                            <span className="sr-only">, {person.name}</span>
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium sm:pr-3"></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
