import { useState } from "react";
import { Animate, Icon, ButtonAdd } from "components/lib";
import EmojiPicker from "emoji-picker-react";
import SentOptionsModal from "./sendOptionsModal";
import Style from "./chatInput.tailwind";

const Send = (props) => {
  const [sendMessageClick, setMessageClick] = useState(true);
  const [emoji, setEmoji] = useState(false);
  const [sendMessageTypeOptions, setSendMessageTypeOptions] = useState(false);
  const toggleEmoji = () => setEmoji(!emoji);
  addEventListener("keydown", (e) => {
    if (e.key === "Escape" && emoji) setEmoji(false);
  });

  return (
    <div className={Style.container}>
      <ButtonAdd
        text="Send"
        className={Style.sendButton}
        action={() => props.sendMessage(props.message)}
      />
      <div className={Style.iconsContainer}>
        <div onClick={toggleEmoji}>
          <Icon image="smile" size={23} className={Style.icon} />
          {emoji && <div className={Style.blocker} onClick={() => setEmoji(false)} />}
        </div>
        <div onClick={(e) => props.createAutomatedResponse(e, props.conversation)}>
          <Icon image="edit-3" size={25} className={Style.icon} />
        </div>
        <div onClick={() => setSendMessageTypeOptions(!sendMessageTypeOptions)}>
          {sendMessageTypeOptions && <div className={Style.blocker} onClick={() => setSendMessageTypeOptions(false)} />}
          <div className="relative">
            <Icon image="more-vertical" size={25} className={Style.icon} />
            {sendMessageTypeOptions && (
              <Animate type="pop" timeout="100">
                <SentOptionsModal
                  setMessageClick={setMessageClick}
                  setMessageEnter={props.setMessageEnter}
                  sendMessageClick={sendMessageClick}
                  sendMessageEnter={props.sendMessageEnter}
                  setSendMessageTypeOptions={setSendMessageTypeOptions}
                />
              </Animate>
            )}
          </div>
        </div>
      </div>
      {emoji && (
        <div className={Style.emojisContainer}>
          <EmojiPicker onEmojiClick={(e) => props.setMessage((prev) => prev + e.emoji)} />
        </div>
      )}
    </div>
  );
};

export default Send; 
