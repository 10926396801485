import React, { createContext, useState } from 'react';
import { DAYS_OF_THE_WEEK, WORKING_HOURS } from '../../lib/consts/settings';

export const NewCampaignContext = createContext();

const NewCampaignProvider = ({ children }) => {
  const [campaignDetails, setCampaignDetails] = useState({
    name: '',
    description: '',
    working_days: '',
    search_url: '',
    linkedinAccount_ids: '',
    connection_request_message: '',
    follow_up_messages: '',
    settings: '',
  });
  const [editCampaing, setEditCampaing] = useState({
    name: '',
    description: '',
    working_days: '',
    connection_request_message: '',
  });
  const [followUpMessages, setFollowUpMessages] = useState([
    {
      message: '',
      number: '0',
      timeframe: 'days',
    },
  ]);
  const [followUpMessage, setFollowUpMessage] = useState([]);
  // const [daysOfTheWeekSelected, setDaysOfTheWeekSelected] = useState(
  //   DAYS_OF_THE_WEEK.map((dotw) => {
  //     return {
  //       name: dotw,
  //       selected: false,
  //     };
  //   })
  // );
  const [daysOfTheWeekSelected, setDaysOfTheWeekSelected] =
    useState(DAYS_OF_THE_WEEK);
  const [workingHoursSelected, setWorkingHoursSelected] = useState(
    WORKING_HOURS.map((wh) => {
      return {
        name: wh,
        selected: false,
      };
    }),
  );
  return (
    <NewCampaignContext.Provider
      value={{
        campaignDetails,
        setCampaignDetails,
        editCampaing,
        setEditCampaing,
        followUpMessages,
        setFollowUpMessages,
        followUpMessage,
        setFollowUpMessage,
        daysOfTheWeekSelected,
        setDaysOfTheWeekSelected,
        workingHoursSelected,
        setWorkingHoursSelected,
      }}
    >
      {children}
    </NewCampaignContext.Provider>
  );
};

export default NewCampaignProvider;
