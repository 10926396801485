const Style = {
  title: "text-lg text-slate-700 font-semibold h-[5%] tracking-wider",
  inboxWrapper: "xl:w-1/4 md:w-1/2 h-full flex flex-col",
  innerWrapper: "flex-1 overflow-y-hidden rounded-b-lg border border-slate-300 bg-slate-100",
  subtitle: "py-1 flex items-center border-b shadow-sm border-slate-300 text-slate-600 font-semibold h-[5%] tracking-wider pl-2",
  noLeadsWrapper: "h-[90%] flex items-center justify-center",
  noLeadsTitle: "text-base text-slate-500 text-center",
};

export default Style;
