import { Grid, Chart, Form, Card, TabViewCustom, useAPI } from 'components/lib';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Style from "./replyRate.tailwind";
import 'react-datepicker/dist/react-datepicker.css';

const ReplyRate = () => {
  const campaigns = useSelector((state) => state.linkedboostStore.campaigns);
  const stats = useSelector((state) => state.linkedboostStore.stats);
  const overallMetrics = useAPI('/api/list-leads-metrics');
  const [replyChart, setReplyChart] = useState({});
  const [replyBar, setReplyBar] = useState({});
  const [chartTitle, setChartTitle] = useState('Overall Reply Rate Chart');
  const [barsTitle, setBarsTitle] = useState('Overall Reply Rate Bars');
  const selectOptions = campaigns.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  useEffect(() => {
    if (overallMetrics.data) {
      if (overallMetrics.data.totalLeads > 0) {
        setReplyChart({
          data: {
            labels: ['Total Leads', 'Requested Connections', 'Accepted Connections', 'Pending Connections'],
            datasets: [
              {
                label: 'Responded',
                data: [
                  overallMetrics.data.totalLeads,
                  overallMetrics.data.totalRequestedConnections,
                  overallMetrics.data.totalAcceptedConnections,
                  overallMetrics.data.totalPendingConnections,
                ],
                borderColor: '#FFFFFF',
                backgroundColor: [
                  '#8D8CC3',
                  '#9A99CB',
                  '#A6A5D7',
                  '#B6B5E4',
                  '#C5C4F1',
                  '#CECDF8',
                ],
                hoverBorderColor: 'transparent',
              },
            ],
          },
          loading: false,
        });
        setReplyBar({
          data: {
            labels: [''],
            datasets: [
              {
                label: 'Total Leads',
                data: [overallMetrics.data.totalLeads,
                ],
                borderColor: '#FFFFFF',
                backgroundColor: [
                  '#8D8CC3',
                  '#9A99CB',
                  '#A6A5D7',
                  '#B6B5E4',
                  '#C5C4F1',
                  '#CECDF8',
                ],
                hoverBorderColor: 'transparent',
              },
              {
                label: 'Requested Connections',
                data: [overallMetrics.data.totalRequestedConnections,
                ],
                borderColor: '#FFFFFF',
                backgroundColor: [
                  '#8D8CC3',
                  '#9A99CB',
                  '#A6A5D7',
                  '#B6B5E4',
                  '#C5C4F1',
                  '#CECDF8',
                ],
                hoverBorderColor: 'transparent',
              },
              {
                label: 'Accepted Connections',
                data: [
                  overallMetrics.data.totalAcceptedConnections,
                ],
                borderColor: '#FFFFFF',
                backgroundColor: [
                  '#8D8CC3',
                  '#9A99CB',
                  '#A6A5D7',
                  '#B6B5E4',
                  '#C5C4F1',
                  '#CECDF8',
                ],
                hoverBorderColor: 'transparent',
              },
              {
                label: 'Pending Connections',
                data: [
                  overallMetrics.data.totalPendingConnections,
                ],
                borderColor: '#FFFFFF',
                backgroundColor: [
                  '#8D8CC3',
                  '#9A99CB',
                  '#A6A5D7',
                  '#B6B5E4',
                  '#C5C4F1',
                  '#CECDF8',
                ],
                hoverBorderColor: 'transparent',
              },
            ],
          },
          loading: false,
        });
      }
    } else {
      setReplyChart({
        data: {
          labels: ['No data'],
          datasets: [
            {
              label: 'Responded',
              data: [
                1,
              ],
              borderColor: '#FFFFFF',
              backgroundColor: [
                '#8D8CC3',
                '#9A99CB',
                '#A6A5D7',
                '#B6B5E4',
                '#C5C4F1',
                '#CECDF8',
              ],
              hoverBorderColor: 'transparent',
            },
          ],
        },
        loading: false,
      });
      setReplyBar(replyBar);
    }
  }, [overallMetrics.data]);

  return (
    <>
      <div className={Style.formContainer}>
        <Form
          className={Style.form}
          data={{
            campaign: {
              label: 'Select a Campaign',
              type: 'select',
              options: selectOptions.length ? selectOptions : [{ label: 'No campaigns yet, add one...', value: 'No campaigns yet, add one...' }],
            },
          }}
          containerClass="w-full lg:w-1/2"
          updateOnChange
          onChange={(e) => {
            const selectedCampaign = stats.campaignsInfo.find(item => item.campaignId === e.value);
            if (e.value !== 'unselected') {
              setChartTitle(`${selectedCampaign.campaignName} Reply Rate Chart`);
              setBarsTitle(`${selectedCampaign.campaignName} Reply Rate Bars`);
              setReplyChart({
                data: {
                  labels: ['Total Leads', 'Requested Connections', 'Accepted Connections', 'Pending Connections'],
                  datasets: [
                    {
                      label: 'Responded',
                      data: [
                        selectedCampaign.leads,
                        selectedCampaign.requestedConnections.total,
                        selectedCampaign.acceptedConnections.total,
                        selectedCampaign.pendingConnections.total,
                      ],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                  ],
                },
                loading: false,
              });
              setReplyBar({
                data: {
                  labels: [''],
                  datasets: [
                    {
                      label: 'Total Leads',
                      data: [selectedCampaign.leads],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                    {
                      label: 'Requested Connections',
                      data: [selectedCampaign.requestedConnections.total],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                    {
                      label: 'Accepted Connections',
                      data: [
                        selectedCampaign.acceptedConnections.total,
                      ],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                    {
                      label: 'Pending Connections',
                      data: [
                        selectedCampaign.pendingConnections.total,
                      ],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                  ],
                },
                loading: false,
              });
            } else {
              setBarsTitle('Overall Reply Rate Bars');
              setChartTitle('Overall Reply Rate Chart');
              setReplyChart({
                data: {
                  labels: ['Total Leads', 'Requested Connections', 'Accepted Connections', 'Pending Connections'],
                  datasets: [
                    {
                      label: 'Responded',
                      data: [
                        overallMetrics.data.totalLeads,
                        overallMetrics.data.totalRequestedConnections,
                        overallMetrics.data.totalAcceptedConnections,
                        overallMetrics.data.totalPendingConnections,
                      ],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                  ],
                },
                loading: false,
              });
              setReplyBar({
                data: {
                  labels: [''],
                  datasets: [
                    {
                      label: 'Total Leads',
                      data: [overallMetrics.data.totalLeads,
                      ],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                    {
                      label: 'Requested Connections',
                      data: [overallMetrics.data.totalRequestedConnections,
                      ],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                    {
                      label: 'Accepted Connections',
                      data: [
                        overallMetrics.data.totalAcceptedConnections,
                      ],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                    {
                      label: 'Pending Connections',
                      data: [
                        overallMetrics.data.totalPendingConnections,
                      ],
                      borderColor: '#FFFFFF',
                      backgroundColor: [
                        '#8D8CC3',
                        '#9A99CB',
                        '#A6A5D7',
                        '#B6B5E4',
                        '#C5C4F1',
                        '#CECDF8',
                      ],
                      hoverBorderColor: 'transparent',
                    },
                  ],
                },
                loading: false,
              });
            }
          }}
        />
      </div>
      <Grid cols="2">
        <Card title={chartTitle}>
          <Chart
            title="Accepted Connections"
            type="pie"
            legend
            data={replyChart.data && replyChart.data}
            xAxis
            yAxis
            abbr
            height={200}
            color="purple"
            loading={!replyChart.data ? true : false}
          />
        </Card>
        <Card title={barsTitle}>
          <Chart
            title="Accepted Connections"
            type="bar"
            legend
            data={replyBar.data && replyBar.data}
            xAxis
            yAxis
            height={200}
            loading={!replyBar.data ? true : false}
          />
        </Card>
      </Grid>
    </>
  );
};

export default ReplyRate;
