import { Icon } from "components/lib";

const ChatPagination = ({ chatsPerPage, totalChats, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalChats / chatsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="flex mt-2 justify-center items-center">
      <Icon image="chevron-left" className="cursor-pointer hover:scale-125" />
      <ul className="flex justify-center items-center">
        {pageNumbers.map((item) => (
          <li key={item}
            onClick={() => paginate(item)}
            className={
              `${currentPage === item && 'bg-[#1F2937] text-white font-semibold text-sm'} 
              mx-1 hover:bg-[#3B82F6] hover:text-white cursor-pointer rounded-sm px-2 text-lg font-semibold`
            }>
            <a>
              {item}
            </a>
          </li>
        ))}
      </ul>
      <Icon image="chevron-right" className="cursor-pointer hover:scale-125" />
    </nav>
  );
};

export default ChatPagination;

