import { Card, ButtonAdd } from 'components/lib';
import Styles from "./header.tailwind";

const Header = ({ title, buttonText, action }) => (
  <Card title={title} className={Styles.container}>
    <div className={Styles.descContainer}>
      <p className={Styles.desc}>
        Have control over your accounts.Check their performance, gather
        information and delete when requirements are met.
      </p>
      <div className={Styles.btnContainer}>
        <ButtonAdd
          text={buttonText}
          action={action}
          redirect
          className={Styles.btn}
        />
      </div>
    </div>
  </Card>
);

export default Header;
