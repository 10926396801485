import { useDispatch } from "react-redux";
import Message from "../message/message";

const MessageChatCards = ({ users, updateFavorite }) => {
  const dispatch = useDispatch();

  return (
    <div className="h-full">
      {users.map((user, index) => {
        return <Message
          key={index}
          index={index}
          user={user}
          users={users}
          dispatch={dispatch}
          id={index + 1}
          updateFavorite={updateFavorite}
        />;
      })}
    </div>
  );
};

export default MessageChatCards;
