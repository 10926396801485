import { Label, Error, ClassHelper, Icon } from 'components/lib';
import Style from './selectFollowUp.tailwind';

export function SelectFollowUp(props) {
  let options = props.options;
  const error = props.errorMessage || 'Please select an option';

  function change(e) {
    let value = e ? e.target?.value : 'unselected';
    let valid = undefined;

    // validate
    valid = props.required && value === 'unselected' ? false : true;
    props.onChange(props.name, value, valid);
    props.callback && props.callback(value);
  }

  const wrapperStyle = ClassHelper(Style, {
    className: props.className,
    success: props.valid === true,
    errorWrapper: props.valid === false,
    warningWrapper: props.warning,
  });

  const selectStyle = ClassHelper(Style, {
    select: true,
    error: props.valid === false,
    warning: props.warning,
  });

  return (
    <div className={Style.input + ' ' + props.containerClass}>
      <Label text={props.label} required={props.required} for={props.name} />
      <div className={wrapperStyle}>
        <select
          className={selectStyle}
          defaultValue={props.default}
          onChange={(e) => change(e)}
          id={props.name}
        >
          {options?.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
        {props.valid === false && (
          <Error message={error} className={Style.message} />
        )}
      </div>
      {!props.custom.length ? (
        ''
      ) : (
        <div className="w-full min-h-16">
          <div className="border border-slate-300 min-h-[77%] rounded flex flex-wrap p-2 items-center overflow-auto">
            {props.custom.map((item, i) => (
              <div
                key={i}
                className="w-fit px-3 rounded shadow-sm flex h-10 items-center mr-2 mb-2 md:mb-0 hover:scale-[103%] hover:font-bold"
                style={{ backgroundColor: '#60A5FA' }}
              >
                <h1 className="text-white">{item[1]}</h1>
                <div onClick={() => props.onDeleteLinkedin(item)}>
                  <Icon
                    image="x"
                    color="light"
                    size={20}
                    className="cursor-pointer ml-2 hover:text-red-500 font-bold"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
