export const formData1 = (values) => [
  {
    name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Enter a campaign title',
      required: true,
      containerClass: 'md:w-[49%]',
      class: 'border border-slate-300',
      value: values.name,
    },
    description: {
      label: 'Description',
      type: 'text',
      placeholder: 'Add a brief campaign description',
      required: true,
      containerClass: 'md:w-[49%]',
      class: 'border border-slate-300',
      value: values.description,
    },
    work_week_days: {
      label: 'Working days',
      type: 'selectCustom',
      containerClass: 'w-full lg:w-[49%]',
      options: [
        { value: 0, label: 'S' },
        { value: 1, label: 'M' },
        { value: 2, label: 'T' },
        { value: 3, label: 'W' },
        { value: 4, label: 'T' },
        { value: 5, label: 'F' },
        { value: 6, label: 'S' },
      ],
      value: JSON.parse(localStorage.getItem('WorkingDays')),
    },
    start_work_hour: {
      label: 'Start time',
      type: 'selectTime',
      default: 8,
      containerClass: 'w-full md:w-[49.5%] lg:w-[23.5%]',
      class: 'border border-slate-300',
      options: [
        { value: 1, label: '01:00 am' },
        { value: 2, label: '02:00 am' },
        { value: 3, label: '03:00 am' },
        { value: 4, label: '04:00 am' },
        { value: 5, label: '05:00 am' },
        { value: 6, label: '06:00 am' },
        { value: 7, label: '07:00 am' },
        { value: 8, label: '08:00 am' },
        { value: 9, label: '09:00 am' },
        { value: 10, label: '10:00 am' },
        { value: 11, label: '11:00 am' },
        { value: 12, label: '12:00 pm' },
        { value: 13, label: '01:00 pm' },
        { value: 14, label: '02:00 pm' },
        { value: 15, label: '03:00 pm' },
        { value: 16, label: '04:00 pm' },
        { value: 17, label: '05:00 pm' },
        { value: 18, label: '06:00 pm' },
        { value: 19, label: '07:00 pm' },
        { value: 20, label: '08:00 pm' },
        { value: 21, label: '09:00 pm' },
        { value: 22, label: '10:00 pm' },
        { value: 23, label: '11:00 pm' },
        { value: 24, label: '12:00 am' },
      ],
      value: values.start_work_hour,
    },
    end_work_hour: {
      label: 'End time',
      type: 'selectTime',
      default: 17,
      containerClass: 'w-full md:w-[49.5%] lg:w-[23.5%]',
      class: 'border border-slate-300',
      options: [
        { value: 1, label: '01:00 am' },
        { value: 2, label: '02:00 am' },
        { value: 3, label: '03:00 am' },
        { value: 4, label: '04:00 am' },
        { value: 5, label: '05:00 am' },
        { value: 6, label: '06:00 am' },
        { value: 7, label: '07:00 am' },
        { value: 8, label: '08:00 am' },
        { value: 9, label: '09:00 am' },
        { value: 10, label: '10:00 am' },
        { value: 11, label: '11:00 am' },
        { value: 12, label: '12:00 pm' },
        { value: 13, label: '01:00 pm' },
        { value: 14, label: '02:00 pm' },
        { value: 15, label: '03:00 pm' },
        { value: 16, label: '04:00 pm' },
        { value: 17, label: '05:00 pm' },
        { value: 18, label: '06:00 pm' },
        { value: 19, label: '07:00 pm' },
        { value: 20, label: '08:00 pm' },
        { value: 21, label: '09:00 pm' },
        { value: 22, label: '10:00 pm' },
        { value: 23, label: '11:00 pm' },
        { value: 24, label: '12:00 am' },
      ],
      value: values.end_work_hour,
    },
    toggle: {
      label: 'Have a csv file?',
      type: 'switch',
      default: JSON.parse(localStorage.getItem('updateFile')),
    },
    search_url: {
      label: 'Search URL',
      type: JSON.parse(localStorage.getItem("updateFile")) ? 'hidden' : 'text',
      placeholder: 'Paste here the linkedin search link',
      containerClass: 'w-full',
      class: 'border border-slate-300',
      value: values.search_url,
    },
    upload_csv: {
      label: 'Upload csv',
      type: !JSON.parse(localStorage.getItem("updateFile")) ? 'hidden' : 'file',
      containerClass: 'w-full mb-3 cursor-pointer',
      class: 'border border-slate-300',
      value: JSON.parse(localStorage.getItem('file')),
      required: true,
    },
    linkedinAccount_ids: {
      label:
        !JSON.parse(localStorage.getItem('SalesNav'))
          ? 'Connect a single linkedin account to this campaign'
          : 'Connect more than one sales account to this campaign',
      type:
        !JSON.parse(localStorage.getItem('SalesNav'))
          ? 'selectWindowSingle'
          : 'selectWindow',
      placeholder: 'Choose a registered Linkedin account',
      containerClass: 'w-full',
      class: 'border border-slate-300',
      options:
        localStorage.getItem('linkedins') &&
        JSON.parse(localStorage.getItem('linkedins')),
      value: values.linkedinAccounts_ids,
    },
    connection_request_message: {
      label: 'Connection Request',
      type: 'textarea',
      placeholder: 'Enter a connection request message',
      class: 'h-18 border border-slate-300',
      containerClass: 'sm:w-full lg:w-1/2',
      value: values.connection_request_message,
    },
    follow_up_messages: {
      label: 'Follow up message',
      type: 'hidden',
      placeholder: 'Enter a message',
      value: [],
    },
    settings: {
      label: 'Campaing Settings',
      type: 'hidden',
      value: { profile_visits_per_day: 20, number_of_pages_to_scrape: 2 },
    },
  },
];

export const formDataUpdate = (values) => [
  {
    name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Enter a campaign title',
      required: true,
      containerClass: 'md:w-[49%]',
      class: 'border border-slate-300',
      value: values.name,
    },
    description: {
      label: 'Description',
      type: 'text',
      placeholder: 'Add a brief campaign description',
      required: true,
      containerClass: 'md:w-[49%]',
      class: 'border border-slate-300',
      value: values.description,
    },
    work_week_days: {
      label: 'Working days',
      type: 'selectCustom',
      containerClass: 'w-full lg:w-[49%]',
      options: [
        { value: 0, label: 'S' },
        { value: 1, label: 'M' },
        { value: 2, label: 'T' },
        { value: 3, label: 'W' },
        { value: 4, label: 'T' },
        { value: 5, label: 'F' },
        { value: 6, label: 'S' },
      ],
      value: JSON.parse(localStorage.getItem('WorkingDays')),
    },
    start_work_hour: {
      label: 'Start time',
      type: 'selectTime',
      default: 8,
      containerClass: 'w-full md:w-[49.5%] lg:w-[23.5%]',
      class: 'border border-slate-300',
      options: [
        { value: 1, label: '01:00 am' },
        { value: 2, label: '02:00 am' },
        { value: 3, label: '03:00 am' },
        { value: 4, label: '04:00 am' },
        { value: 5, label: '05:00 am' },
        { value: 6, label: '06:00 am' },
        { value: 7, label: '07:00 am' },
        { value: 8, label: '08:00 am' },
        { value: 9, label: '09:00 am' },
        { value: 10, label: '10:00 am' },
        { value: 11, label: '11:00 am' },
        { value: 12, label: '12:00 pm' },
        { value: 13, label: '01:00 pm' },
        { value: 14, label: '02:00 pm' },
        { value: 15, label: '03:00 pm' },
        { value: 16, label: '04:00 pm' },
        { value: 17, label: '05:00 pm' },
        { value: 18, label: '06:00 pm' },
        { value: 19, label: '07:00 pm' },
        { value: 20, label: '08:00 pm' },
        { value: 21, label: '09:00 pm' },
        { value: 22, label: '10:00 pm' },
        { value: 23, label: '11:00 pm' },
        { value: 24, label: '12:00 am' },
      ],
      value: values.start_work_hour,
    },
    end_work_hour: {
      label: 'End time',
      type: 'selectTime',
      default: 17,
      containerClass: 'w-full md:w-[49.5%] lg:w-[23.5%]',
      class: 'border border-slate-300',
      options: [
        { value: 1, label: '01:00 am' },
        { value: 2, label: '02:00 am' },
        { value: 3, label: '03:00 am' },
        { value: 4, label: '04:00 am' },
        { value: 5, label: '05:00 am' },
        { value: 6, label: '06:00 am' },
        { value: 7, label: '07:00 am' },
        { value: 8, label: '08:00 am' },
        { value: 9, label: '09:00 am' },
        { value: 10, label: '10:00 am' },
        { value: 11, label: '11:00 am' },
        { value: 12, label: '12:00 pm' },
        { value: 13, label: '01:00 pm' },
        { value: 14, label: '02:00 pm' },
        { value: 15, label: '03:00 pm' },
        { value: 16, label: '04:00 pm' },
        { value: 17, label: '05:00 pm' },
        { value: 18, label: '06:00 pm' },
        { value: 19, label: '07:00 pm' },
        { value: 20, label: '08:00 pm' },
        { value: 21, label: '09:00 pm' },
        { value: 22, label: '10:00 pm' },
        { value: 23, label: '11:00 pm' },
        { value: 24, label: '12:00 am' },
      ],
      value: values.end_work_hour,
    },
    toggle: {
      label: 'Have a csv file?',
      type: 'hidden',
      default: undefined,
    },
    search_url: {
      label: 'Search URL',
      type: 'hidden',
      placeholder: 'Paste here the linkedin search link',
      containerClass: 'w-full',
      class: 'border border-slate-300',
      default: undefined,
    },
    upload_csv: {
      label: 'Upload csv',
      type: 'hidden',
      containerClass: 'w-full mb-3 cursor-pointer',
      class: 'border border-slate-300',
      default: undefined,
    },
    linkedinAccount_ids: {
      label:
        !JSON.parse(localStorage.getItem('SalesNav'))
          ? 'Connect a single linkedin account to this campaign'
          : 'Connect more than one sales account to this campaign',
      type: 'hidden',
      placeholder: 'Choose a registered Linkedin account',
      containerClass: 'w-full',
      class: 'border border-slate-300',
      options:
        localStorage.getItem('linkedins') &&
        JSON.parse(localStorage.getItem('linkedins')),
      default: undefined,
    },
    connection_request_message: {
      label: 'Connection Request',
      type: 'textarea',
      placeholder: 'Enter a connection request message',
      class: 'h-18 border border-slate-300',
      containerClass: 'sm:w-full lg:w-1/2',
      value: values.connection_request_message,
    },
    follow_up_messages: {
      label: 'Follow up message',
      type: 'hidden',
      placeholder: 'Enter a message',
      value: [],
    },
    settings: {
      label: 'Campaing Settings',
      type: 'hidden',
      value: { profile_visits_per_day: 20, number_of_pages_to_scrape: 2 },
    },
  },
];

export const formDataFollowUp = [
  {
    title: {
      label: 'Title',
      type: 'hidden',
      placeholder: 'Enter a campaign title',
      // required: true,
    },
    description: {
      label: 'Description',
      type: 'hidden',
      placeholder: 'Add a brief campaign description',
      // required: true,
    },
    search_url: {
      label: 'Search URL',
      type: 'hidden',
      placeholder: 'Paste here the linkedin search link',
      // required: true,
    },
    select_linkedin: {
      label: 'Select Linkedin',
      type: 'hidden',
      placeholder: 'Choose a registered Linkedin account',
      // required: true,
    },
    connection_request: {
      label: 'Connection Request',
      type: 'hidden',
      placeholder: 'Enter a connection request message',
    },
    follow_up_messages: {
      label: 'Follow up message',
      type: 'hidden',
      placeholder: 'Enter a message',
      // default: localStorage.getItem("followUps"),
      required: true,
    },
    campaign_settings: {
      label: 'Campaing Settings',
      type: 'hidden',
      placeholder: 'Enter a message',
      // default: localStorage.getItem("settings"),
      required: true,
    },
  },
];
